import { Text, Title } from "@mantine/core";
import { useApp } from "../../../contexts/AppContext";
interface UserWelcomeSectionProps {
  userName: string;
}

export const UserWelcomeSection = ({ userName }: UserWelcomeSectionProps) => {
  const { deviceType } = useApp();
  return (
    <div
      style={{
        marginBottom:
          deviceType === "Mobile" || deviceType === "Tablet" ? "0px" : "",
      }}
    >
      <Title
        order={3}
        size={
          deviceType === "Mobile" || deviceType === "Tablet" ? "20px" : "30px"
        }
        fw={600}
      >
        Welcome {userName},{" "}
      </Title>

      <Text
        fw={400}
        c={"rgba(20, 24, 31, 0.7)"}
        mt={10}
        size={
          deviceType === "Mobile" || deviceType === "Tablet" ? "12px" : "16px"
        }
      >
        Let’s finalize a few details for your visa application.
      </Text>
    </div>
  );
};
