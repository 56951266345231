import axios from "axios";
import apiUtil from "../utils/api.util";

class documentAIService {
  route = import.meta.env.VITE_DOC_AI_API_URL;

  processDocuments = async (
    docFiles: File[],
    applicantUid: string
  ): Promise<void> => {
    const formData = new FormData();
    const apiHeaders = await apiUtil.getHeader();

    docFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("applicantUid", applicantUid);
    const formResponse = await axios.postForm(
      `${this.route}/process-docs`,
      formData,
      {
        headers: apiHeaders,
      }
    );
    return formResponse.data as any;
  };

  getDocFieldsByApplicantUid = async (applicantUid: string): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();

    const response = await axios.get(
      `${this.route}/get-doc-applicantUid?applicantUid=${applicantUid}`,
      {
        headers: apiHeaders,
      }
    );

    return response.data as any;
  };

  getExtractedDocByApplicantUid = async (
    applicantUid?: string,
    docIds?: string[],
    includePageDetails: boolean = true
  ): Promise<any> => {
    const apiHeaders = await apiUtil.getHeader();

    const response = await axios.post(
      `${this.route}/get-extracted-doc?applicantUid=${applicantUid}`,
      {
        docIds,
        includePageDetails,
      },
      {
        headers: apiHeaders,
      }
    );

    return response as any;
  };

  getUploadedDocsByApplicantUid = async (
    applicantUid: string
  ): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();

    const response = await axios.get(
      `${this.route}/get-uploaded-docs?applicantUid=${applicantUid}`,
      {
        headers: apiHeaders,
      }
    );

    return response.data as any;
  };

  getExtractedDocsByDocIds = async (
    docIds: Array<string>,
    applicantUid: string
  ): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.route}/docs-by-docIds`,
      {
        docIds,
        applicantUid,
      },
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  updateExtractedDoc = async (
    docFields: any,
    docId: string,
    applicantUid: string
  ): Promise<any> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.route}/update-doc`,
      {
        docFields,
        docId,
        applicantUid,
      },
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  markAsPrimary = async (
    docFields: any,
    docId: string,
    applicantUid: string,
    updateAll?: boolean
  ): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.route}/mark-primary`,
      {
        docFields,
        docId,
        applicantUid,
        updateAll,
      },
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  deleteById = async (docId: string): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.delete(
      `${this.route}/delete-doc?docId=${docId}`,
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  createPDFfromSelectedImages = async (
    applicantUid: string,
    document: any
  ): Promise<any> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.route}/create-pdf-from-images`,
      {
        applicantUid,
        documentData: document,
      },
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  splitDocumentByCategory = async (
    applicantUid: string,
    document: any
  ): Promise<any> => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.route}/split-pdf-by-categories`,
      {
        applicantUid,
        documentData: document[0],
      },
      {
        headers: apiHeaders,
      }
    );

    return response.data;
  };

  getExtractedDocsByApplicantUid = async (
    applicantUid: string
  ): Promise<void> => {
    const apiHeaders = await apiUtil.getHeader();

    const response = await axios.get(
      `${this.route}/get-docs-applicantUid?applicantUid=${applicantUid}`,
      {
        headers: apiHeaders,
      }
    );

    return response.data as any;
  };

  processUpdatedCategory = async (
    applicantUid: string,
    docId: string,
    category: string,
    file: any,
    pageIndex: number
  ): Promise<any> => {
    const response = await apiUtil.post(
      `${this.route}/process-edited-category`,
      { applicantUid, docId, category, file, pageIndex }
    );

    return response;
  };
}

export default new documentAIService();
