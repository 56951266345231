import {
  darken,
  lighten,
  Stepper,
  StepperProps,
  useMantineTheme,
} from "@mantine/core";
import { useApplication } from "../../../contexts/ApplicationContext";
import "./stepper.css";
interface ApplicationProgressStepperProps {
  handleStepClick: (index: number) => void;
  steps: {
    label: string;
    description: string;
  }[];
  buttonStyles?: React.CSSProperties;
  primaryColor?: string;
}

const getButtonStyles = (
  index: number,
  activeStep: number,
  primaryColor: string | undefined,
  touchedStepperColor: string
) => {
  const theme = useMantineTheme();
  const primary = theme.colors[theme.primaryColor]?.[0];
  const isCompleted = index < activeStep;
  const isActive = index === activeStep;
  return {
    borderRadius: "100px",
    borderWidth: "2px",
    height: "45px ",
    alignItems: "center",
    display: "flex",
    padding: "16px 20px",
    backdropFilter: "blur(20px)",
    border: isActive ? `2px solid ${primaryColor ?? primary}` : "none",
    boxShadow: "inset 0 3px 10px 0 rgba(255, 255, 255, 0.16)",
    backgroundColor:
      isCompleted || isActive
        ? touchedStepperColor
        : "rgba(155, 155, 155, 0.08)",
    color: isActive
      ? primaryColor
        ? darken(primaryColor, 0.2)
        : darken(primary, 0.2)
      : "black",
    fontWeight: "500",
    cursor: "default",
  };
};

const StyledStepper = (props: StepperProps) => {
  return <Stepper {...props} />;
};

export const ApplicationProgressStepper = ({
  handleStepClick,
  steps,
  primaryColor,
}: ApplicationProgressStepperProps) => {
  const { activeStep } = useApplication();
  const theme = useMantineTheme();
  const primary = theme.colors[theme.primaryColor]?.[0];
  const touchedStepperColor = lighten(primary, 0.88);

  const stepperStyles = {
    stepBody: { display: "none" },
    step: { padding: 0 },
    stepIcon: { borderWidth: 4 },
    separator: {
      marginLeft: -8,
      marginRight: -8,
      height: 3,
    },
    flexWrap: "nowrap",
  };

  return (
    <StyledStepper
      active={activeStep ?? -1}
      data-active={activeStep ?? -1}
      styles={stepperStyles}
      onStepClick={() => {}}
    >
      {steps?.map((step, index) => (
        <Stepper.Step
          key={index}
          label={step.label}
          renderRoot={() => (
            <button
              onClick={() => {}}
              style={{
                ...getButtonStyles(
                  index,
                  activeStep ?? -1,
                  primaryColor!,
                  touchedStepperColor!
                ),
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {step.label}
            </button>
          )}
        />
      ))}
    </StyledStepper>
  );
};
