import React from "react";
import toast from "react-hot-toast";
import { AuthenticationComponent } from "../../components/authentication/AuthenticationScreen";
import {
  IV_RF_TOKEN,
  IV_TOKEN,
  IV_USER,
  REFRESH_TOKEN_EXPIRES_IN,
  TOKEN_EXPIRES_IN,
} from "../../constants/storage.constants";
import { useUser } from "../../contexts/UserContext";
import authService from "../../services/auth.service";
import profileService from "../../services/profile.service";

interface LoginResponse {
  tokens: {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    refresh_expires_in: string;
  };
  userData: {
    id: string;
    role: string[];
    profile?: any;
  };
}

const AuthScreen = () => {
  const { setCurrentUserProfile, filterRoles } = useUser();
  const [loading, setLoading] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [showPasswordChange, setShowPasswordChange] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const handleSignIn = async () => {
    const loadingToast = () =>
      toast.loading("Signing in... Please wait.", { position: "top-right" });

    try {
      setLoading(true);
      loadingToast();

      if (showPasswordChange) {
        if (newPassword !== confirmPassword) {
          toast.error("New password and confirm password do not match!", {
            position: "top-right",
          });
          return;
        }
      }

      const response = (await authService.login(
        userName,
        password,
        showPasswordChange ? newPassword : ""
      )) as
        | LoginResponse
        | { requiresPasswordChange: boolean; message: string };

      if ("requiresPasswordChange" in response) {
        setShowPasswordChange(true);
        toast.dismiss();
        toast(response.message, {
          position: "top-right",
          icon: "🔒",
        });
        return;
      }

      // Continue with existing login success logic
      const { tokens, userData } = response as LoginResponse;

      // Handle remember me functionality

      localStorage.setItem(IV_TOKEN, tokens.access_token);
      localStorage.setItem(IV_RF_TOKEN, tokens.refresh_token);
      localStorage.setItem(TOKEN_EXPIRES_IN, tokens?.expires_in);
      localStorage.setItem(
        REFRESH_TOKEN_EXPIRES_IN,
        tokens?.refresh_expires_in
      );

      // Fetch user profile
      const profileRes = await profileService.profile(
        userData.id,
        filterRoles!(userData.role)?.[0]
      );

      // Update user response with profile
      const updateduserData = {
        ...(profileRes?.response || {}),
        ...userData,
        role: filterRoles!(userData.role),
      };

      // Handle remember me functionality
      localStorage.setItem(IV_USER, JSON.stringify(updateduserData));

      // Update global user context
      setCurrentUserProfile!(updateduserData);

      toast.dismiss();
      // toast.success("Signed in successfully!", { position: "top-right" });
    } catch (error) {
      toast.dismiss();
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error occurred";
      toast.error(`Error while signing in... ${errorMessage}`, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <AuthenticationComponent
        loading={loading}
        userName={userName}
        password={password}
        showPasswordChange={showPasswordChange}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        onPasswordChange={(password) => setPassword(password)}
        onUserNameChange={(userName) => setUserName(userName)}
        onNewPasswordChange={(newPassword) => setNewPassword(newPassword)}
        onConfirmPasswordChange={(confirmPassword) =>
          setConfirmPassword(confirmPassword)
        }
        onSignIn={() => handleSignIn()}
      />
    </div>
  );
};

export default AuthScreen;
