import { createTheme, MantineThemeOverride } from "@mantine/core";
import { lighten, darken } from "polished";

/**
 * Generate shades of a color
 * @param primaryColor
 * @returns
 */
const generateShades = (primaryColor: string): string[] => {
  const shades = [primaryColor];
  for (let i = 1; i <= 5; i++) {
    shades.push(lighten(i * 0.1, primaryColor));
  }

  shades.push(primaryColor);
  for (let i = 1; i <= 4; i++) {
    shades.push(darken(i * 0.1, primaryColor));
  }
  return shades;
};

export const generateTheme = (primaryColor: string): MantineThemeOverride => {
  const colors = generateShades(primaryColor);

  while (colors.length < 10) {
    colors.push(primaryColor);
  }

  return {
    colors: {
      brand: colors as [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        ...string[],
      ],
    },
    primaryColor: "brand",
  };
};

export const b2bTheme = createTheme({});
