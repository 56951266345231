export const SUPPORTED_DOCS = [
  "Passport",
  "Proof of Funds",
  "Insurance",
  "Proof of Travel",
  "Letter",
  "Proof of Employment",
  "Photo",
  "Other",
  "National ID",
  "Proof of Status",
  "Proof of Relationship",
  "Proof of Marital Status",
  "Proof of Address",
];

export const ROLES = {
  ADMIN: "admin",
  AGENCY: "agency",
  AGENT: "agent",
  APPLICANT: "applicant",
  REVIEWER: "reviewer",
  DISTRIBUTOR: "distributor",
};

export const FORM_STATUS = {
  DRAFTED: "DRAFTED",
  SUBMITTED: "SUBMITTED",
};

export const APPLICATION_STATUS = {
  DRAFT: "DRAFT",
  DRAFT_UES: "DRAFT - User Email Sent",
  DRAFT_URR: "DRAFT - User Response Received",
  DRAFT_SFR: "DRAFT - Sent for Review",
  DRAFT_RRR: "DRAFT - Review Response Received",
  PACKAGE: "Package Creation In Progress",
  ERROR_INC_INFO: "Error - Incomplete Info",
  COMPLETED: "Package ready",
  DATA_COLLECTED: "Form-data collected",
  STARTED: "Online form started",
  FINISHED: "Online form completed",
  CONFIRMED: "Application submitted",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
};

export const AGENCY_TYPES = {
  D2C: "D2C",
  B2B: "B2B",
  B2B2C: "B2B2C",
};

export const APPLICANT_TYPES = {
  D2C: "D2C",
  B2B: "B2B",
};

export const AGENCY_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  DISTRIBUTOR: "DISTRIBUTOR",
};

export const REVIEWER_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  AGENCY: "AGENCY",
};

export const DISTRIBUTOR_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const REVIEW_TYPES = {
  REVIEW: "REVIEW",
  QUERY: "QUERY",
};

export const visaTypes = {
  "": "Tourism",
  "Standard Visitor Visa (Tourism)": "Tourism",
  "2-Year Long-term Standard Visitor Visa": "Tourism",
  "5-Year Long-term Standard Visitor Visa": "Tourism",
  "10-Year Long-term Standard Visitor Visa": "Tourism",
  "Standard Visitor Visa (Study)": "Tourism",
  "Standard Visitor Visa (Business)": "Business",
  "Visitor Visa (Tourist)": "Tourism",
  "Visitor Visa (Business)": "Business",
  "Student Visa": "Student",
  "Child Student Visa": "Student",
  "Schengen Visa (Type C) – Tourism": "Tourism",
  "Schengen Visa (Type C) – Business": "Business",
  "National Visa (Type D) – Students": "Student",
};
