import React from "react";
import { Card, Text, Badge, Group, Image, Container } from "@mantine/core";
import StaffIcon from "../../../assets/staff.svg";
import CouponIcon from "../../../assets/coupon.svg";
import LetterIcon from "../../../assets/letter.svg";

interface PremiumServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  badgeLabel?: string;
  badgeColor?: string;
  buttonText: string;
  cardBackground?: string;
  cardTextColor?: string;
}

const PremiumServiceCard: React.FC<PremiumServiceCardProps> = ({
  icon,
  title,
  description,
  badgeLabel,
  badgeColor,
  buttonText,
  cardBackground = "rgba(255, 255, 255, 0.11)", // Transparent grayish background
  cardTextColor = "white",
}) => {
  return (
    <Card
      shadow="md"
      padding="24px"
      radius="16px"
      style={{
        background: cardBackground,
        backdropFilter: "blur(10px)", // Blurred effect
        WebkitBackdropFilter: "blur(10px)", // Safari support
        border: "1px solid rgba(255, 255, 255, 0.4)",
        backgroundBlendMode: "luminosity",
        color: cardTextColor,
        minWidth: "301px",
        borderRadius: "16px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Group gap="10" style={{ position: "relative" }}>
        {typeof icon === "string" ? (
          <Image src={icon} alt="Icon" width={32} height={32} />
        ) : (
          icon
        )}
        {badgeLabel && (
          <Badge
            color={badgeColor || "blue"}
            variant="filled"
            style={{
              position: "absolute",
              gap: "10px",
              fontSize: "12px",
              padding: "4px 10px",
              borderRadius: "16px",
              fontWeight: "600",
              right: 60,
              top: 15,
              transform: "translate(50%, -50%)",
            }}
          >
            {badgeLabel}
          </Badge>
        )}
      </Group>

      <Text fw={600} size="18px" mt="md">
        {title}
      </Text>

      <Text color="light" fw={400} size="14px" mt="xs">
        {description}
      </Text>

      <Text
        variant="gradient"
        gradient={{ from: "orange", to: "red" }}
        color="red"
        mt="md"
        fw={600}
        size="14px"
      >
        {buttonText}
      </Text>
    </Card>
  );
};

export const PremiumServiceCardGrid: React.FC = () => {
  const serviceData: PremiumServiceCardProps[] = [
    {
      icon: <img src={StaffIcon} alt="" />,
      title: "Assign to Visa Staff",
      description:
        "Let a Visa Staff member review and submit your application.",
      badgeLabel: "MOST USED",
      badgeColor: "blue",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.11)",
      cardTextColor: "black",
    },
    {
      icon: <img src={CouponIcon} alt="" />,
      title: "Get Insurance & Flight",
      description:
        "Get exclusive discounts on travel insurance and flight bookings.",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.11)",
      cardTextColor: "black",
    },
    {
      icon: <img src={LetterIcon} alt="" />,
      title: "We’ll Write Your Cover Letter",
      description:
        "We’ll draft a professional cover letter for your visa application.",
      badgeLabel: "RECOMMENDED",
      badgeColor: "blue",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.11)",
      cardTextColor: "black",
    },
  ];

  return (
    <Container
      fluid
      style={{
        width: "100%",
        padding: "24px",
        zIndex: "1",
        background: "rgba(155, 155, 155, 0.08)",
        backdropFilter: "blur(48px)",
        WebkitBackdropFilter: "blur(48px)",
        borderRadius: "16px",
        border: "1.4px solid rgba(255, 255, 255, 0.4)",
        boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
      }}
    >
      <Group
        gap="24"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "nowrap",
        }}
      >
        {serviceData.map((item, index) => (
          <PremiumServiceCard key={index} {...item} />
        ))}
      </Group>
    </Container>
  );
};
