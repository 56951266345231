import { ICountry } from "../types/visaRequirements.type";

export const SUPPORTED_COUNTRIES_FROM_INDIA = [
  {
    value: "GB",
    label: "United Kingdom",
    alpha3: "GBR",
  },
  {
    value: "AU",
    label: "Australia",
    alpha3: "AUS",
  },
  {
    value: "DE",
    label: "Germany",
    alpha3: "DEU",
  },
  {
    value: "CH",
    label: "Switzerland",
    alpha3: "CHE",
  },
  {
    value: "JP",
    label: "Japan",
    alpha3: "JPN",
  },
  {
    value: "VN",
    label: "Vietnam",
    alpha3: "VNM",
  },
  {
    value: "AZ",
    label: "Azerbaijan",
    alpha3: "AZE",
  },
  {
    value: "ID",
    label: "Indonesia",
    alpha3: "IDN",
  },
  {
    value: "ES",
    label: "Spain",
    alpha3: "ESP",
  },
  {
    value: "SG",
    label: "Singapore",
    alpha3: "SGP",
  },
  {
    value: "NZ",
    label: "New Zealand",
    alpha3: "NZL",
  },
  {
    value: "CA",
    label: "Canada",
    alpha3: "CAN",
  },
  {
    value: "BE",
    label: "Belgium",
    alpha3: "BEL",
  },
  {
    value: "IT",
    label: "Italy",
    alpha3: "ITA",
  },
  {
    value: "FR",
    label: "France",
    alpha3: "FRA",
  },
  {
    value: "PL",
    label: "Poland",
    alpha3: "POL",
  },
  {
    value: "PT",
    label: "Portugal",
    alpha3: "PRT",
  },
  {
    value: "NL",
    label: "Netherlands",
    alpha3: "NLD",
  },
];

export const SUPPORTED_FROM_COUNTRIES = [
  {
    value: "AF",
    label: "Afghanistan",
    alpha3: "AFG",
  },
  {
    value: "AL",
    label: "Albania",
    alpha3: "ALB",
  },
  {
    value: "DZ",
    label: "Algeria",
    alpha3: "DZA",
  },
  {
    value: "AD",
    label: "Andorra",
    alpha3: "AND",
  },
  {
    value: "AO",
    label: "Angola",
    alpha3: "AGO",
  },
  {
    value: "AG",
    label: "Antigua and Barbuda",
    alpha3: "ATG",
  },
  {
    value: "AR",
    label: "Argentina",
    alpha3: "ARG",
  },
  {
    value: "AM",
    label: "Armenia",
    alpha3: "ARM",
  },
  {
    value: "AU",
    label: "Australia",
    alpha3: "AUS",
  },
  {
    value: "AT",
    label: "Austria",
    alpha3: "AUT",
  },
  {
    value: "AZ",
    label: "Azerbaijan",
    alpha3: "AZE",
  },
  {
    value: "BS",
    label: "Bahamas",
    alpha3: "BHS",
  },
  {
    value: "BH",
    label: "Bahrain",
    alpha3: "BHR",
  },
  {
    value: "BD",
    label: "Bangladesh",
    alpha3: "BGD",
  },
  {
    value: "BB",
    label: "Barbados",
    alpha3: "BRB",
  },
  {
    value: "BY",
    label: "Belarus",
    alpha3: "BLR",
  },
  {
    value: "BE",
    label: "Belgium",
    alpha3: "BEL",
  },
  {
    value: "BZ",
    label: "Belize",
    alpha3: "BLZ",
  },
  {
    value: "BJ",
    label: "Benin",
    alpha3: "BEN",
  },
  {
    value: "BM",
    label: "Bermuda",
    alpha3: "BMU",
  },
  {
    value: "BT",
    label: "Bhutan",
    alpha3: "BTN",
  },
  {
    value: "BO",
    label: "Bolivia",
    alpha3: "BOL",
  },
  {
    value: "BA",
    label: "Bosnia and Herzegovina",
    alpha3: "BIH",
  },
  {
    value: "BW",
    label: "Botswana",
    alpha3: "BWA",
  },
  {
    value: "BR",
    label: "Brazil",
    alpha3: "BRA",
  },
  {
    value: "BN",
    label: "Brunei",
    alpha3: "BRN",
  },
  {
    value: "BG",
    label: "Bulgaria",
    alpha3: "BGR",
  },
  {
    value: "BF",
    label: "Burkina Faso",
    alpha3: "BFA",
  },
  {
    value: "BI",
    label: "Burundi",
    alpha3: "BDI",
  },
  {
    value: "KH",
    label: "Cambodia",
    alpha3: "KHM",
  },
  {
    value: "CM",
    label: "Cameroon",
    alpha3: "CMR",
  },
  {
    value: "CA",
    label: "Canada",
    alpha3: "CAN",
  },
  {
    value: "CV",
    label: "Cape Verde",
    alpha3: "CPV",
  },
  {
    value: "CF",
    label: "Central African Republic",
    alpha3: "CAF",
  },
  {
    value: "TD",
    label: "Chad",
    alpha3: "TCD",
  },
  {
    value: "CL",
    label: "Chile",
    alpha3: "CHL",
  },
  {
    value: "CN",
    label: "China",
    alpha3: "CHN",
  },
  {
    value: "CO",
    label: "Colombia",
    alpha3: "COL",
  },
  {
    value: "KM",
    label: "Comoros",
    alpha3: "COM",
  },
  {
    value: "CR",
    label: "Costa Rica",
    alpha3: "CRI",
  },
  {
    value: "HR",
    label: "Croatia",
    alpha3: "HRV",
  },
  {
    value: "CU",
    label: "Cuba",
    alpha3: "CUB",
  },
  {
    value: "CY",
    label: "Cyprus",
    alpha3: "CYP",
  },
  {
    value: "CZ",
    label: "Czech Republic",
    alpha3: "CZE",
  },
  {
    value: "CD",
    label: "Democratic Rep. of the Congo",
    alpha3: "COD",
  },
  {
    value: "DK",
    label: "Denmark",
    alpha3: "DNK",
  },
  {
    value: "DJ",
    label: "Djibouti",
    alpha3: "DJI",
  },
  {
    value: "DM",
    label: "Dominica",
    alpha3: "DMA",
  },
  {
    value: "DO",
    label: "Dominican Republic",
    alpha3: "DOM",
  },
  {
    value: "TL",
    label: "East Timor",
    alpha3: "TLS",
  },
  {
    value: "EC",
    label: "Ecuador",
    alpha3: "ECU",
  },
  {
    value: "EG",
    label: "Egypt",
    alpha3: "EGY",
  },
  {
    value: "SV",
    label: "El Salvador",
    alpha3: "SLV",
  },
  {
    value: "GQ",
    label: "Equatorial Guinea",
    alpha3: "GNQ",
  },
  {
    value: "ER",
    label: "Eritrea",
    alpha3: "ERI",
  },
  {
    value: "EE",
    label: "Estonia",
    alpha3: "EST",
  },
  {
    value: "SZ",
    label: "Eswatini",
    alpha3: "SWZ",
  },
  {
    value: "ET",
    label: "Ethiopia",
    alpha3: "ETH",
  },
  {
    value: "FJ",
    label: "Fiji",
    alpha3: "FJI",
  },
  {
    value: "FI",
    label: "Finland",
    alpha3: "FIN",
  },
  {
    value: "FR",
    label: "France",
    alpha3: "FRA",
  },
  {
    value: "GA",
    label: "Gabon",
    alpha3: "GAB",
  },
  {
    value: "GM",
    label: "Gambia",
    alpha3: "GMB",
  },
  {
    value: "GE",
    label: "Georgia",
    alpha3: "GEO",
  },
  {
    value: "DE",
    label: "Germany",
    alpha3: "DEU",
  },
  {
    value: "GH",
    label: "Ghana",
    alpha3: "GHA",
  },
  {
    value: "GR",
    label: "Greece",
    alpha3: "GRC",
  },
  {
    value: "GD",
    label: "Grenada",
    alpha3: "GRD",
  },
  {
    value: "GT",
    label: "Guatemala",
    alpha3: "GTM",
  },
  {
    value: "GN",
    label: "Guinea",
    alpha3: "GIN",
  },
  {
    value: "GW",
    label: "Guinea-Bissau",
    alpha3: "GNB",
  },
  {
    value: "GY",
    label: "Guyana",
    alpha3: "GUY",
  },
  {
    value: "HT",
    label: "Haiti",
    alpha3: "HTI",
  },
  {
    value: "HN",
    label: "Honduras",
    alpha3: "HND",
  },
  {
    value: "HK",
    label: "Hong Kong SAR",
    alpha3: "HKG",
  },
  {
    value: "HU",
    label: "Hungary",
    alpha3: "HUN",
  },
  {
    value: "IS",
    label: "Iceland",
    alpha3: "ISL",
  },
  {
    value: "IN",
    label: "India",
    alpha3: "IND",
  },
  {
    value: "ID",
    label: "Indonesia",
    alpha3: "IDN",
  },
  {
    value: "IR",
    label: "Iran",
    alpha3: "IRN",
  },
  {
    value: "IQ",
    label: "Iraq",
    alpha3: "IRQ",
  },
  {
    value: "IE",
    label: "Ireland",
    alpha3: "IRL",
  },
  {
    value: "IL",
    label: "Israel",
    alpha3: "ISR",
  },
  {
    value: "IT",
    label: "Italy",
    alpha3: "ITA",
  },
  {
    value: "CI",
    label: "Ivory Coast",
    alpha3: "CIV",
  },
  {
    value: "JM",
    label: "Jamaica",
    alpha3: "JAM",
  },
  {
    value: "JP",
    label: "Japan",
    alpha3: "JPN",
  },
  {
    value: "JO",
    label: "Jordan",
    alpha3: "JOR",
  },
  {
    value: "KZ",
    label: "Kazakhstan",
    alpha3: "KAZ",
  },
  {
    value: "KE",
    label: "Kenya",
    alpha3: "KEN",
  },
  {
    value: "KI",
    label: "Kiribati",
    alpha3: "KIR",
  },
  {
    value: "XK",
    label: "Kosovo",
    alpha3: "XKX",
  },
  {
    value: "KW",
    label: "Kuwait",
    alpha3: "KWT",
  },
  {
    value: "KG",
    label: "Kyrgyzstan",
    alpha3: "KGZ",
  },
  {
    value: "LA",
    label: "Laos",
    alpha3: "LAO",
  },
  {
    value: "LV",
    label: "Latvia",
    alpha3: "LVA",
  },
  {
    value: "LB",
    label: "Lebanon",
    alpha3: "LBN",
  },
  {
    value: "LS",
    label: "Lesotho",
    alpha3: "LSO",
  },
  {
    value: "LR",
    label: "Liberia",
    alpha3: "LBR",
  },
  {
    value: "LY",
    label: "Libya",
    alpha3: "LBY",
  },
  {
    value: "LI",
    label: "Liechtenstein",
    alpha3: "LIE",
  },
  {
    value: "LT",
    label: "Lithuania",
    alpha3: "LTU",
  },
  {
    value: "LU",
    label: "Luxembourg",
    alpha3: "LUX",
  },
  {
    value: "MO",
    label: "Macao SAR",
    alpha3: "MAC",
  },
  {
    value: "MG",
    label: "Madagascar",
    alpha3: "MDG",
  },
  {
    value: "MW",
    label: "Malawi",
    alpha3: "MWI",
  },
  {
    value: "MY",
    label: "Malaysia",
    alpha3: "MYS",
  },
  {
    value: "MV",
    label: "Maldives",
    alpha3: "MDV",
  },
  {
    value: "ML",
    label: "Mali",
    alpha3: "MLI",
  },
  {
    value: "MT",
    label: "Malta",
    alpha3: "MLT",
  },
  {
    value: "MH",
    label: "Marshall Islands",
    alpha3: "MHL",
  },
  {
    value: "MR",
    label: "Mauritania",
    alpha3: "MRT",
  },
  {
    value: "MU",
    label: "Mauritius",
    alpha3: "MUS",
  },
  {
    value: "MX",
    label: "Mexico",
    alpha3: "MEX",
  },
  {
    value: "FM",
    label: "Micronesia",
    alpha3: "FSM",
  },
  {
    value: "MD",
    label: "Moldova",
    alpha3: "MDA",
  },
  {
    value: "MC",
    label: "Monaco",
    alpha3: "MCO",
  },
  {
    value: "MN",
    label: "Mongolia",
    alpha3: "MNG",
  },
  {
    value: "ME",
    label: "Montenegro",
    alpha3: "MNE",
  },
  {
    value: "MA",
    label: "Morocco",
    alpha3: "MAR",
  },
  {
    value: "MZ",
    label: "Mozambique",
    alpha3: "MOZ",
  },
  {
    value: "MM",
    label: "Myanmar",
    alpha3: "MMR",
  },
  {
    value: "NA",
    label: "Namibia",
    alpha3: "NAM",
  },
  {
    value: "NR",
    label: "Nauru",
    alpha3: "NRU",
  },
  {
    value: "NP",
    label: "Nepal",
    alpha3: "NPL",
  },
  {
    value: "NL",
    label: "Netherlands",
    alpha3: "NLD",
  },
  {
    value: "NZ",
    label: "New Zealand",
    alpha3: "NZL",
  },
  {
    value: "NI",
    label: "Nicaragua",
    alpha3: "NIC",
  },
  {
    value: "NE",
    label: "Niger",
    alpha3: "NER",
  },
  {
    value: "NG",
    label: "Nigeria",
    alpha3: "NGA",
  },
  {
    value: "MK",
    label: "North Macedonia",
    alpha3: "MKD",
  },
  {
    value: "NO",
    label: "Norway",
    alpha3: "NOR",
  },
  {
    value: "OM",
    label: "Oman",
    alpha3: "OMN",
  },
  {
    value: "PK",
    label: "Pakistan",
    alpha3: "PAK",
  },
  {
    value: "PW",
    label: "Palau",
    alpha3: "PLW",
  },
  {
    value: "PS",
    label: "Palestine",
    alpha3: "PSE",
  },
  {
    value: "PA",
    label: "Panama",
    alpha3: "PAN",
  },
  {
    value: "PG",
    label: "Papua New Guinea",
    alpha3: "PNG",
  },
  {
    value: "PY",
    label: "Paraguay",
    alpha3: "PRY",
  },
  {
    value: "PE",
    label: "Peru",
    alpha3: "PER",
  },
  {
    value: "PH",
    label: "Philippines",
    alpha3: "PHL",
  },
  {
    value: "PL",
    label: "Poland",
    alpha3: "POL",
  },
  {
    value: "PT",
    label: "Portugal",
    alpha3: "PRT",
  },
  {
    value: "QA",
    label: "Qatar",
    alpha3: "QAT",
  },
  {
    value: "CG",
    label: "Republic of Congo",
    alpha3: "COG",
  },
  {
    value: "RO",
    label: "Romania",
    alpha3: "ROU",
  },
  {
    value: "RU",
    label: "Russia",
    alpha3: "RUS",
  },
  {
    value: "RW",
    label: "Rwanda",
    alpha3: "RWA",
  },
  {
    value: "KN",
    label: "Saint Kitts and Nevis",
    alpha3: "KNA",
  },
  {
    value: "LC",
    label: "Saint Lucia",
    alpha3: "LCA",
  },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
    alpha3: "VCT",
  },
  {
    value: "WS",
    label: "Samoa",
    alpha3: "WSM",
  },
  {
    value: "SM",
    label: "San Marino",
    alpha3: "SMR",
  },
  {
    value: "ST",
    label: "Sao Tome and Principe",
    alpha3: "STP",
  },
  {
    value: "SA",
    label: "Saudi Arabia",
    alpha3: "SAU",
  },
  {
    value: "SN",
    label: "Senegal",
    alpha3: "SEN",
  },
  {
    value: "RS",
    label: "Serbia",
    alpha3: "SRB",
  },
  {
    value: "SC",
    label: "Seychelles",
    alpha3: "SYC",
  },
  {
    value: "SL",
    label: "Sierra Leone",
    alpha3: "SLE",
  },
  {
    value: "SG",
    label: "Singapore",
    alpha3: "SGP",
  },
  {
    value: "SK",
    label: "Slovakia",
    alpha3: "SVK",
  },
  {
    value: "SI",
    label: "Slovenia",
    alpha3: "SVN",
  },
  {
    value: "SB",
    label: "Solomon Islands",
    alpha3: "SLB",
  },
  {
    value: "SO",
    label: "Somalia",
    alpha3: "SOM",
  },
  {
    value: "ZA",
    label: "South Africa",
    alpha3: "ZAF",
  },
  {
    value: "KR",
    label: "South Korea",
    alpha3: "KOR",
  },
  {
    value: "SS",
    label: "South Sudan",
    alpha3: "SSD",
  },
  {
    value: "ES",
    label: "Spain",
    alpha3: "ESP",
  },
  {
    value: "LK",
    label: "Sri Lanka",
    alpha3: "LKA",
  },
  {
    value: "SD",
    label: "Sudan",
    alpha3: "SDN",
  },
  {
    value: "SR",
    label: "Suriname",
    alpha3: "SUR",
  },
  {
    value: "SE",
    label: "Sweden",
    alpha3: "SWE",
  },
  {
    value: "CH",
    label: "Switzerland",
    alpha3: "CHE",
  },
  {
    value: "SY",
    label: "Syria",
    alpha3: "SYR",
  },
  {
    value: "TW",
    label: "Taiwan",
    alpha3: "TWN",
  },
  {
    value: "TJ",
    label: "Tajikistan",
    alpha3: "TJK",
  },
  {
    value: "TZ",
    label: "Tanzania",
    alpha3: "TZA",
  },
  {
    value: "TH",
    label: "Thailand",
    alpha3: "THA",
  },
  {
    value: "TG",
    label: "Togo",
    alpha3: "TGO",
  },
  {
    value: "TO",
    label: "Tonga",
    alpha3: "TON",
  },
  {
    value: "TT",
    label: "Trinidad and Tobago",
    alpha3: "TTO",
  },
  {
    value: "TN",
    label: "Tunisia",
    alpha3: "TUN",
  },
  {
    value: "TM",
    label: "Turkmenistan",
    alpha3: "TKM",
  },
  {
    value: "TV",
    label: "Tuvalu",
    alpha3: "TUV",
  },
  {
    value: "TR",
    label: "Türkiye",
    alpha3: "TUR",
  },
  {
    value: "UG",
    label: "Uganda",
    alpha3: "UGA",
  },
  {
    value: "UA",
    label: "Ukraine",
    alpha3: "UKR",
  },
  {
    value: "AE",
    label: "United Arab Emirates",
    alpha3: "ARE",
  },
  {
    value: "GB",
    label: "United Kingdom",
    alpha3: "GBR",
  },
  {
    value: "US",
    label: "United States",
    alpha3: "USA",
  },
  {
    value: "UY",
    label: "Uruguay",
    alpha3: "URY",
  },
  {
    value: "UZ",
    label: "Uzbekistan",
    alpha3: "UZB",
  },
  {
    value: "VU",
    label: "Vanuatu",
    alpha3: "VUT",
  },
  {
    value: "VA",
    label: "Vatican City",
    alpha3: "VAT",
  },
  {
    value: "VE",
    label: "Venezuela",
    alpha3: "VEN",
  },
  {
    value: "VN",
    label: "Vietnam",
    alpha3: "VNM",
  },
  {
    value: "YE",
    label: "Yemen",
    alpha3: "YEM",
  },
  {
    value: "ZM",
    label: "Zambia",
    alpha3: "ZMB",
  },
  {
    value: "ZW",
    label: "Zimbabwe",
    alpha3: "ZWE",
  },
];

export const SUPPORTED_TO_COUNTRIES = [
  {
    value: "GB",
    label: "United Kingdom",
    alpha3: "GBR",
  },
  {
    value: "AU",
    label: "Australia",
    alpha3: "AUS",
  },
  {
    value: "DE",
    label: "Germany",
    alpha3: "DEU",
  },
  {
    value: "CH",
    label: "Switzerland",
    alpha3: "CHE",
  },
  {
    value: "BE",
    label: "Belgium",
    alpha3: "BEL",
  },
  {
    value: "IT",
    label: "Italy",
    alpha3: "ITA",
  },
  {
    value: "FR",
    label: "France",
    alpha3: "FRA",
  },
  {
    value: "PL",
    label: "Poland",
    alpha3: "POL",
  },
  {
    value: "PT",
    label: "Portugal",
    alpha3: "PRT",
  },
  {
    value: "NL",
    label: "Netherlands",
    alpha3: "NLD",
  },
];

/**
 * Interface defining the structure of a country object
 */
export interface Country {
  value: string;
  label: string;
  alpha3: string;
}

/**
 * Gets a country by alpha3 code from the SUPPORTED_COUNTRIES_FROM_INDIA list
 * @param alpha3 The alpha3 code to search for
 * @returns The matching country object or undefined if not found
 */
export const getCountryFromIndiaByAlpha3 = (
  alpha3: string
): Country | undefined => {
  return SUPPORTED_COUNTRIES_FROM_INDIA.find(
    (country) => country.alpha3 === alpha3
  );
};

/**
 * Gets a country by alpha3 code from the SUPPORTED_FROM_COUNTRIES list
 * @param alpha3 The alpha3 code to search for
 * @returns The matching country object or undefined if not found
 */
export const getFromCountryByAlpha3 = (alpha3: string): Country | undefined => {
  return SUPPORTED_FROM_COUNTRIES.find((country) => country.alpha3 === alpha3);
};

/**
 * Gets a country by alpha3 code from the SUPPORTED_TO_COUNTRIES list
 * @param alpha3 The alpha3 code to search for
 * @returns The matching country object or undefined if not found
 */
export const getToCountryByAlpha3 = (alpha3: string): Country | undefined => {
  return SUPPORTED_TO_COUNTRIES.find((country) => country.alpha3 === alpha3);
};

/**
 * Gets all supported nationalities from all supported countries
 * @returns An array of country objects
 */
export const getSupportedNationalities = (): Country[] => {
  return SUPPORTED_COUNTRIES_FROM_INDIA;
};

/**
 * Gets a country by alpha3 code from all supported country lists
 * @param alpha3 The alpha3 code to search for
 * @returns The matching country object or undefined if not found
 */
export const getCountryByAlpha3 = (alpha3: string): Country | undefined => {
  return (
    getCountryFromIndiaByAlpha3(alpha3) ||
    getFromCountryByAlpha3(alpha3) ||
    getToCountryByAlpha3(alpha3)
  );
};

/**
 * Gets a country by label from all supported country lists
 * @param label The country label to search for
 * @returns The matching country or default empty country object
 */
export const getCountryByLabel = (label: string): ICountry => {
  const foundCountry =
    SUPPORTED_COUNTRIES_FROM_INDIA?.find(
      (country) => country.label === label
    ) ||
    SUPPORTED_FROM_COUNTRIES?.find((country) => country.label === label) ||
    SUPPORTED_TO_COUNTRIES?.find((country) => country.label === label);

  return foundCountry
    ? { country: foundCountry.label, countryISO: foundCountry.value }
    : { country: "", countryISO: "" };
};
