import { IRouteObject } from "../types/route.types";
import { ROLES } from "../constants/common.constants";
import React from "react";

const B2BRoutes: Array<IRouteObject> = [
  {
    path: "/",
    exact: true,
    component: React.lazy(() =>
      import("../components/homeScreen/Home").then((module) => ({
        default: module.Home,
      }))
    ),
    roles: [ROLES.AGENT],
  },
  {
    path: "/home",
    exact: true,
    component: React.lazy(() =>
      import("../components/homeScreen/Home").then((module) => ({
        default: module.Home,
      }))
    ),
    roles: [ROLES.AGENT],
  },
  {
    path: "/analytics",
    exact: true,
    component: React.lazy(() =>
      import("../screens/analytics/AnalyticsScreen").then((module) => ({
        default: module.AnalyticsScreen,
      }))
    ),
    roles: [ROLES.ADMIN, ROLES.DISTRIBUTOR],
  },
  {
    path: "/fileUpload",
    exact: true,
    component: React.lazy(
      () => import("../screens/fileUploads/FileUploadScreen")
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/fileUpload/document-playground",
    exact: true,
    component: React.lazy(
      () => import("../screens/documentPlayground/DocumentPlayground")
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT],
  },
  {
    path: "/fileUpload/document-editor",
    exact: true,
    component: React.lazy(() =>
      import("../screens/documentEditor/DocumentEditorScreen").then(
        (module) => ({
          default: module.default,
        })
      )
    ),
    roles: [ROLES.AGENT, ROLES.AGENCY],
  },
  {
    path: "/applications/document-editor",
    exact: true,
    component: React.lazy(() =>
      import("../screens/documentEditor/DocumentEditorScreen").then(
        (module) => ({
          default: module.default,
        })
      )
    ),
    roles: [ROLES.AGENT, ROLES.AGENCY],
  },
  {
    path: "/get-visa",
    exact: true,
    component: React.lazy(() =>
      import("../screens/getVisa/GetVisaScreen").then((module) => ({
        default: module.GetVisaScreen,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT],
  },
  {
    path: "/india-to-uk/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/uk/UkForm").then((module) => ({
        default: module.UserDataUkForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-japan/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/japan/JapanForm").then((module) => ({
        default: module.UserDataJapanForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-germany/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/germany/GermanyForm").then((module) => ({
        default: module.UserDataGermanyForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-swiz/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/switzerland/SwizForm").then(
        (module) => ({
          default: module.UserDataSwitzerlandForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-vietnam/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/vietnam/VietnamForm").then((module) => ({
        default: module.UserDataVietnamForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-azerbaijan/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/azerbaijan/AzerbaijanForm").then(
        (module) => ({
          default: module.UserDataAzerbaijanForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-newZealand/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/newZealand/newZealandForm").then(
        (module) => ({
          default: module.UserDataNewZealandForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-australia/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/australia/AustraliaForm").then(
        (module) => ({
          default: module.UserDataAustraliaForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-canada/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/canada/CanadaForm").then((module) => ({
        default: module.UserDataCanadaForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-indonesia/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/indonesia/indonesiaForm").then(
        (module) => ({
          default: module.UserDataIndonesiaForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-spain/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/spain/SpainForm").then((module) => ({
        default: module.UserDataSpainForm,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/india-to-singapore/form",
    exact: false,
    component: React.lazy(() =>
      import("../screens/userDataForms/singapore/singaporeForm").then(
        (module) => ({
          default: module.UserDataSingaporeForm,
        })
      )
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
  {
    path: "/applicants",
    exact: true,
    component: React.lazy(() =>
      import("../screens/applicant/ApplicantsScreen").then((module) => ({
        default: module.ApplicantListScreen,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT],
  },
  {
    path: "/applications",
    exact: true,
    component: React.lazy(() =>
      import("../screens/application/ApplicationsScreen").then((module) => ({
        default: module.ApplicationListScreen,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.REVIEWER],
  },
  {
    path: "/agents",
    exact: true,
    component: React.lazy(() =>
      import("../screens/agent/AgentScreen").then((module) => ({
        default: module.AgentListScreen,
      }))
    ),
    roles: [ROLES.AGENCY],
  },
  {
    path: "/agency",
    exact: true,
    component: React.lazy(() =>
      import("../screens/agency/AgencyScreen").then((module) => ({
        default: module.AgencyListScreen,
      }))
    ),
    roles: [ROLES.ADMIN, ROLES.DISTRIBUTOR],
  },
  {
    path: "/distributor",
    exact: true,
    component: React.lazy(() =>
      import("../screens/distributor/DistributorScreen").then((module) => ({
        default: module.DistributorListScreen,
      }))
    ),
    roles: [ROLES.ADMIN],
  },
  {
    path: "/reviewers",
    exact: true,
    component: React.lazy(() =>
      import("../screens/reviewer/ReviewerScreen").then((module) => ({
        default: module.ReviewerListScreen,
      }))
    ),
    roles: [ROLES.ADMIN, ROLES.AGENCY],
  },
  {
    path: "/notifications",
    exact: true,
    component: React.lazy(
      () => import("../screens/notifications/NotificationScreen")
    ),
    roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.AGENT],
  },
  {
    path: "/settings",
    exact: true,
    component: React.lazy(() =>
      import("../screens/settings/SettingsScreen").then((module) => ({
        default: module.default,
      }))
    ),
    roles: [
      ROLES.ADMIN,
      ROLES.AGENCY,
      ROLES.AGENT,
      ROLES.DISTRIBUTOR,
      ROLES.REVIEWER,
    ],
  },
  {
    path: "*",
    component: React.lazy(() =>
      import("../components/fourZeroFour/FourZeroFour").then((module) => ({
        default: module.FourZeroFour,
      }))
    ),
    roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT],
  },
  {
    path: "/form",
    component: React.lazy(() =>
      import("../screens/userDataForms/DataFormScreen").then((module) => ({
        default: module.DataFormScreen,
      }))
    ),
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT, ROLES.REVIEWER],
  },
];

const D2CRoutes: Array<IRouteObject> = [
  {
    path: "/",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/application/ApplicationListScreen").then(
        (module) => ({
          default: module.ApplicationListScreen,
        })
      )
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/get-visa",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/routeSelection/VisaSelectionScreen").then(
        (module) => ({
          default: module.VisaSelectionScreen,
        })
      )
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/visa-requirements",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/visaRequirements/VisaRequirementsScreen").then(
        (module) => ({
          default: module.VisaRequirementsScreen,
        })
      )
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/applications",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/application/ApplicationListScreen").then(
        (module) => ({
          default: module.ApplicationListScreen,
        })
      )
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/summary",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/application/ApplicationSummaryScreen").then(
        (module) => ({
          default: module.ApplicationSummaryScreen,
        })
      )
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/document-playground",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/docModule/DocScreen").then((module) => ({
        default: module.DocScreen,
      }))
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/profile",
    exact: true,
    component: React.lazy(() =>
      import("../screens/d2c/profile/ProfileScreen").then((module) => ({
        default: module.ProfilePage,
      }))
    ),
    roles: [ROLES.APPLICANT],
  },
  {
    path: "/form",
    component: React.lazy(() =>
      import("../screens/userDataForms/DataFormScreen").then((module) => ({
        default: module.DataFormScreen,
      }))
    ),
    roles: [ROLES.APPLICANT],
  },
];

/**
 * @method getD2CRoutes to get the array of routes d2c
 */
export const getD2CRoutes = () => D2CRoutes;

/**
 * @method getB2BRoutes to get the array of routes b2b
 */
export const getB2BRoutes = () => B2BRoutes;
