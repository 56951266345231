import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

type Devices = "Mobile" | "Desktop" | "Tablet" | "Default";

export interface IAppContextProps {
  /**
   * Contains device type using media query @see https://github.com/yocontra/react-responsive
   */
  deviceType: Devices | undefined;
  /**
   * Indicates if the current device is running iOS
   */
  isIOS: boolean;
}

interface IAppContextState {
  deviceType: Devices | undefined;
  isIOS: boolean;
}

type IAppContextStateActions =
  | {
      type: "device-type";
      deviceType: Devices | undefined;
    }
  | {
      type: "set-ios";
      isIOS: boolean;
    };

const AppContextStateReducer: React.Reducer<
  IAppContextState,
  IAppContextStateActions
> = (state, action) => {
  switch (action.type) {
    case "device-type":
      return { ...state, deviceType: action.deviceType };
    case "set-ios":
      return { ...state, isIOS: action.isIOS };
  }
};

const AppContext = React.createContext<Partial<IAppContextProps>>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppProvider = React.memo((props: any) => {
  const AppState: IAppContextState = {
    deviceType: undefined,
    isIOS: false,
  };

  const [state, dispatch] = React.useReducer(AppContextStateReducer, AppState);

  //Given by design team
  // const isMobile = useMediaQuery({ maxWidth: 375 });
  // const isTablet = useMediaQuery({ minWidth: 376, maxWidth: 743 });
  // const isDesktop = useMediaQuery({ minWidth: 744 });

  const isMobile = useMediaQuery({ maxWidth: 450 });
  const isTablet = useMediaQuery({ minWidth: 450, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  // Detect iOS device
  React.useEffect(() => {
    const detectIOS = () => {
      const userAgent = window.navigator?.userAgent?.toLowerCase();
      return (
        /iphone|ipad|ipod|macintosh/.test(userAgent) && "ontouchend" in document
      );
    };

    dispatch({ type: "set-ios", isIOS: detectIOS() });
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      dispatch({ type: "device-type", deviceType: "Mobile" });
    } else if (isTablet) {
      dispatch({ type: "device-type", deviceType: "Tablet" });
    } else if (isDesktop) {
      dispatch({ type: "device-type", deviceType: "Desktop" });
    } else {
      dispatch({ type: "device-type", deviceType: "Default" });
    }
  }, [isDesktop, isTablet, isMobile]);

  return (
    <AppContext.Provider
      value={{
        deviceType: state.deviceType,
        isIOS: state.isIOS,
      }}
      {...props}
    />
  );
});

// eslint-disable-next-line react-refresh/only-export-components
export const useApp = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp() must be used inside AppProvider");
  } else {
    return context;
  }
};
