import { LoadingOverlay, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import { Toaster } from "react-hot-toast";
import { AppProvider } from "./contexts/AppContext";
import { ModalsProvider } from "@mantine/modals";
import { UserProvider, useUser } from "./contexts/UserContext";
import { b2bTheme } from "./theme";
import { KeyCloakProvider } from "./contexts/KeyCloakContext";
import "@mantine/dates/styles.css";
import AuthScreen from "./screens/auth/AuthScreen";
import { RouteConfigs } from "./routes/RouteConfigs";

function App() {
  return (
    <React.Suspense fallback={<LoadingOverlay visible={true} />}>
      <MantineProvider
        theme={b2bTheme}
        withCssVariables={true}
        withGlobalClasses={true}
        defaultColorScheme="light"
      >
        <Toaster />
        <ModalsProvider>
          <AppProvider>
            <KeyCloakProvider>
              <UserProvider>
                <AppChildren />
              </UserProvider>
            </KeyCloakProvider>
          </AppProvider>
        </ModalsProvider>
      </MantineProvider>
    </React.Suspense>
  );
}

function AppChildren() {
  const { isAuthenticated } = useUser();

  return isAuthenticated!() ? (
    <RouteConfigs />
  ) : (
    <React.Suspense fallback={<LoadingOverlay visible={true} />}>
      <AuthScreen />
    </React.Suspense>
  );
}

export default App;
