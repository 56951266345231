import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { IAgency } from "../types/agency.types";
import { IAgent } from "../types/agent.types";
import {
  BRAND_COLOR,
  BRAND_LOGO_URL,
  IV_TOKEN,
  IV_USER,
  LAST_LOGOUT_TIME,
  REALM_CLIENT_ID,
} from "../constants/storage.constants";
import { IKCUserData, useKeyCloak } from "./KeyCloakContext";
import profileService from "../services/profile.service";
import { IApplicant } from "../types/applicant.types";

// TODO: & check for the types of the variables for new users
type IUser = IAgent & IAgency & IApplicant;

interface IUserContextProps {
  currentUserProfile: IUser;
  setCurrentUserProfile: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  getUserId: () => string | undefined;
  isAuthenticated: () => boolean;
  logoutCurrentUser: () => void;
  getCurrentUserRoles: () => string[];
  getCurrentRole: () => string[];
  filterRoles: (roles: string[]) => string[];
}

interface IUserContextState {
  isLoaded: boolean;
  currentUserProfile: Partial<IUser>;
}

type UserContextStateActions =
  | { type: "current-user"; currentUserProfile: Partial<IUser> }
  | { type: "loaded"; isLoaded: boolean };

const UserContextStateReducer = (
  state: IUserContextState,
  action: UserContextStateActions
) => {
  switch (action.type) {
    case "loaded":
      return { ...state, isLoaded: action.isLoaded };

    case "current-user":
      return { ...state, currentUserProfile: action.currentUserProfile };

    default:
      return state;
  }
};

export const UserContext = React.createContext<Partial<IUserContextProps>>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserProvider = React.memo((props: any) => {
  const [state, dispatch] = React.useReducer(UserContextStateReducer, {
    currentUserProfile: {},
    isLoaded: false,
  } as IUserContextState);
  const { keycloak, logout } = useKeyCloak();

  const logoutCurrentUser = () => {
    localStorage.removeItem(IV_USER);
    localStorage.removeItem(IV_TOKEN);
    localStorage.removeItem(REALM_CLIENT_ID);
    localStorage.removeItem(BRAND_COLOR);
    localStorage.removeItem(BRAND_LOGO_URL);
    localStorage.removeItem(LAST_LOGOUT_TIME);

    logout?.();

    dispatch({
      type: "current-user",
      currentUserProfile: {},
    });
  };

  const filterRoles = (roles: string[]) => {
    return roles?.filter(
      (role) =>
        role === "admin" ||
        role === "agency" ||
        role === "agent" ||
        role === "applicant" ||
        role === "reviewer" ||
        role === "distributor"
    );
  };

  const handleStoredUserProfile = (storedProfile: string) => {
    const currentUserProfile = JSON.parse(storedProfile);
    dispatch({ type: "current-user", currentUserProfile });
    dispatch({ type: "loaded", isLoaded: true });
  };

  const handleKeycloakUser = async (user: any) => {
    const roles = filterRoles(user?.realm_access?.roles ?? []);
    const userData: Partial<IKCUserData & IUser> = {
      id: user?.sub,
      firstName: user?.given_name,
      lastName: user?.family_name,
      email: user?.email,
      role: roles.length ? roles : ["applicant"],
    };

    if (!userData.id || !userData.role) return;

    try {
      const res = await profileService.profile(
        userData.id,
        filterRoles(userData.role)?.[0]
      );
      const currentUserProfile = res
        ? { ...res.response, ...userData }
        : userData;

      dispatch({ type: "current-user", currentUserProfile });
      localStorage.setItem(IV_USER, JSON.stringify(currentUserProfile));
    } catch (error) {
      dispatch({ type: "current-user", currentUserProfile: userData });
      localStorage.setItem(IV_USER, JSON.stringify(userData));
    }

    dispatch({ type: "loaded", isLoaded: true });
  };

  React.useEffect(() => {
    const initializeUser = async () => {
      const token = localStorage.getItem(IV_TOKEN);
      const storedUserProfile = localStorage.getItem(IV_USER);
      const logoutTime = localStorage.getItem(LAST_LOGOUT_TIME);

      if (!token && !storedUserProfile) {
        dispatch({ type: "current-user", currentUserProfile: {} });
        dispatch({ type: "loaded", isLoaded: true });
        return;
      }

      if (
        logoutTime !== null &&
        logoutTime !== "" &&
        logoutTime !== undefined &&
        token
      ) {
        logoutCurrentUser();
        return;
      }

      if (!token) {
        logoutCurrentUser();
        return;
      }

      if (storedUserProfile) {
        handleStoredUserProfile(storedUserProfile);
        return;
      }

      if (keycloak?.tokenParsed) {
        await handleKeycloakUser(keycloak.tokenParsed!);
      }
    };

    initializeUser();
  }, []);

  if (!state.isLoaded) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <UserContext.Provider
      value={{
        currentUserProfile: state?.currentUserProfile,
        getUserId: () => state?.currentUserProfile?.id || undefined,
        setCurrentUserProfile: (currentUserProfile: Partial<IUser>) => {
          dispatch({ type: "current-user", currentUserProfile });
        },
        getCurrentRole: () =>
          state?.currentUserProfile?.role?.filter(
            (role) =>
              role === "admin" ||
              role === "agency" ||
              role === "agent" ||
              role === "applicant" ||
              role === "reviewer" ||
              role === "distributor"
          ),
        getCurrentUserRoles: () => state?.currentUserProfile?.role || [],
        isAuthenticated: () => localStorage.getItem(IV_TOKEN) !== null,
        logoutCurrentUser,
        filterRoles,
      }}
      {...props}
    />
  );
});

// eslint-disable-next-line react-refresh/only-export-components
export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used inside UserProvider");
  } else {
    return context;
  }
}
