import {
  Group,
  Title,
  Image,
  ThemeIcon,
  Button,
  Avatar,
  Text,
} from "@mantine/core";
import {
  IconBrandChrome,
  IconEdit,
  IconLayoutSidebarLeftCollapse,
  IconLogout,
} from "@tabler/icons-react";
import classes from "./navbarExpanded.module.css";
import IV_LOGO from "../../assets/logo.png";
import { isInRole } from "../../utils/common.util";
import { NavbarProps } from "./NavbarApp";
import { useUser } from "../../contexts/UserContext";
import { truncateText } from "../../utils/common.util";

export function NavbarExpanded({
  data,
  expanded,
  setExpanded,
  navigate,
  logoutCurrentUser,
  getCurrentUserRoles,
  active,
  setActive,
  extensionStatus,
  handleExtensionDownload,
}: NavbarProps) {
  const roles = getCurrentUserRoles!();
  const { currentUserProfile } = useUser();

  const links = data?.map((item) =>
    isInRole(item.roles!, roles) ? (
      <a
        className={classes.link}
        data-active={item.label === active || undefined}
        link-disabled={item.disabled ? "true" : undefined}
        href={item.link}
        key={item.label}
        onClick={(event) => {
          if (item?.disabled === false) {
            event.preventDefault();
            setActive(item.label);
            navigate(item.link);
          } else {
            event.preventDefault();
          }
        }}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.label}</span>
      </a>
    ) : null
  );

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <Group className={classes.header} justify="space-between">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Image
                src={IV_LOGO}
                alt="Intellivisa"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                }}
              />
              <Title
                order={3}
                style={{
                  color: "#181818",
                }}
              >
                Intellivisa
              </Title>
            </div>

            <div style={{}}>
              <ThemeIcon
                style={{
                  cursor: "pointer",
                }}
                color="gray"
                variant="transparent"
                onClick={() => setExpanded(!expanded)}
              >
                <IconLayoutSidebarLeftCollapse />
              </ThemeIcon>
            </div>
          </div>
        </Group>
        {links}
      </div>

      <div className={classes.footer}>
        <div
          style={{
            marginTop: extensionStatus === "installed" ? 0 : 20,
            marginBottom: 20,
          }}
        >
          <a
            href="#"
            className={classes.link}
            style={{
              display: "flex",
              borderLeftWidth: 4,
              borderLeftColor:
                extensionStatus === "installed" ? "green" : "red",
              borderLeftStyle: "solid",
              borderRadius: 0,
            }}
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            {extensionStatus === "installed" && (
              <IconBrandChrome color="green" />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ marginLeft: 10 }}>
                {extensionStatus === "installed"
                  ? "Extension is connected"
                  : "Required Chrome extension is either not installed or active"}
              </span>

              {extensionStatus !== "installed" && (
                <Button
                  style={{
                    backgroundColor: "#FF6B00",
                    color: "white",
                    padding: "1px 1px",
                    borderRadius: 5,
                    border: "none",
                    cursor: "pointer",
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                  leftSection={<IconBrandChrome />}
                  onClick={() => {
                    handleExtensionDownload!();
                  }}
                >
                  Install Now
                </Button>
              )}
            </div>
          </a>
        </div>
      </div>

      <div className={classes.footer}>
        <a
          href="#"
          className={classes.link}
          onClick={(event) => event.preventDefault()}
          style={{
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Avatar size="md" variant="filled" style={{ marginRight: 10 }}>
            {currentUserProfile?.firstName?.charAt(0)?.trim()}
          </Avatar>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 14, fontWeight: 700 }}>
              {truncateText(
                `${currentUserProfile?.firstName ?? ""} ${
                  currentUserProfile?.lastName ?? ""
                }`
              )}
            </Text>
            <Text style={{ fontSize: 14 }}>
              {truncateText(`${currentUserProfile?.companyName ?? "-"}`)}
            </Text>
          </span>
          <a
            href="#"
            style={{ pointerEvents: "auto" }}
            onClick={(event) => {
              event.preventDefault();
              navigate("/settings");
            }}
          >
            <IconEdit className={classes.linkIcon} stroke={1.5} />
          </a>
        </a>
      </div>

      <div className={classes.footer}>
        <a
          href="#"
          className={classes.link}
          style={{ pointerEvents: "auto" }}
          onClick={(event) => {
            event.preventDefault();
            logoutCurrentUser!();
          }}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </div>
    </nav>
  );
}
