export const IV_TOKEN = "IV_TOKEN";
export const IV_RF_TOKEN = "IV_RF_TOKEN";
export const IV_USER = "IV_USER";
export const REALM_CLIENT_ID = "REALM_CLIENT_ID";
export const BRAND_COLOR = "BRAND_COLOR";
export const BRAND_LOGO_URL = "BRAND_LOGO_URL";
export const FAVICON_BASE64 = "FAVICON_BASE64";
export const LAST_LOGOUT_TIME = "LAST_LOGOUT_TIME";
export const TOKEN_EXPIRES_IN = "TOKEN_EXPIRES_IN";
export const REFRESH_TOKEN_EXPIRES_IN = "REFRESH_TOKEN_EXPIRES_IN";
