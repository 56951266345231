import React, { useCallback, useRef, useState } from "react";
import {
  Group,
  Avatar,
  Menu,
  UnstyledButton,
  Box,
  useMantineTheme,
  Text,
  Drawer,
  Accordion,
} from "@mantine/core";
import {
  IconChevronDown,
  IconLogout,
  IconUserCircle,
} from "@tabler/icons-react";
import classes from "./AppHeader.module.css";
import cx from "clsx";
import { PremiumServiceCardGrid } from "../premiumServiceCard/PremiumServiceCard";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import { ApplicationProgressStepper } from "../application/ApplicationProgressStepper";
import { useApplication } from "../../../contexts/ApplicationContext";
import { UserWelcomeSection } from "../userWelcomeSection/UserWelcomeSection";
import { VisaDetailsActionBar } from "../visaDetailsAction/VisaDetailsActionBar";
import { VisaDetailsActionBarMobile } from "../visaDetailsActionBarMobile/VisaDetailsActionBarMobile";
import { visaTypes } from "../../../constants/common.constants";
import { IApplication } from "../../../types/application.types";
import { format } from "date-fns";
import applicationService from "../../../services/application.service";
import { useApp } from "../../../contexts/AppContext";
import MENU_ICON from "../../../assets/menu.svg";
import ICON_BACK from "../../../assets/back.svg";
import { useDisclosure } from "@mantine/hooks";
import ICON_SUPPORT from "../../../../public/support_white.svg";

const links = [
  { link: "/get-visa", label: "Apply now", disabled: false },
  { link: "/applications", label: "My Applications", disabled: false },
  { link: "/document-playground", label: "My Documents", disabled: false },
  {
    link: "",
    label: "Premium Services",
    disabled: false,
    icon: <IconChevronDown size={12} stroke={1.5} />,
  },
];

const mobileLinks = [
  { link: "/get-visa", label: "Apply now", disabled: false },
  { link: "/applications", label: "My Applications", disabled: false },
  { link: "/document-playground", label: "My Documents", disabled: false },
  {
    link: "/profile",
    label: "My Profile",
    disabled: false,
  },
  {
    link: "",
    label: "Logout",
    disabled: false,
  },
];

const applicationSteps = [
  {
    label: "Details",
    description: "Fill the form",
    link: "/get-visa",
  },
  {
    label: "Guidelines",
    description: "Read the guidelines",
    link: "/visa-requirements",
  },
  {
    label: "Upload",
    description: "Upload your documents",
    link: "/document-playground",
  },
  {
    label: "Review",
    description: "Review your form",
    link: "/form",
  },
  {
    label: "Submit",
    description: "Submit your application",
    link: "/summary",
  },
];

interface AppHeaderProps {
  logoUrl?: string;
}

export const AppHeader = React.memo(({ logoUrl }: AppHeaderProps) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [active, setActive] = useState(links[1].link);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { logoutCurrentUser, currentUserProfile } = useUser();
  const [showPremiumServices, setShowPremiumServices] = useState(false);
  const user = {
    image: "/path/to/avatar.jpg",
    name: currentUserProfile?.firstName,
  };
  const navigate = useNavigate();
  const {
    activeStep,
    setActiveStep,
    fromDate,
    toDate,
    application,
    setApplication,
    category,
    fromCountry,
    toCountry,
    applicationUid,
    setFromDate,
    setToDate,
  } = useApplication();
  const theme = useMantineTheme();
  const primaryColor = theme.colors[theme.primaryColor][6];
  const { deviceType } = useApp();

  const headerRef = useRef<HTMLDivElement>(null);
  const premiumServiceRef = useRef<HTMLDivElement>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [activeAccordion, setActiveAccordion] = useState<string | null>();

  const handleBackClick = () => {
    const path = window.location.href;

    const isDraft =
      (application?.status?.toLowerCase()?.includes("draft") ||
        application?.status?.toLowerCase()?.includes("initiated")) ??
      true;

    if (path.includes("form") && isDraft) {
      navigate(
        `/document-playground?applicationUid=${application?.applicationUid}&applicantUid=${application?.applicantUid}`
      );
    } else if (path.includes("document-playground") && isDraft) {
      navigate(`/visa-requirements?applicationUid=${applicationUid}`);
    } else if (path.includes("/visa-requirements")) {
      isDraft
        ? navigate(`/get-visa?applicationUid=${applicationUid}`)
        : navigate(`/get-visa`);
    } else if (path.includes("/summary") && !isDraft) {
      navigate(
        `/form?applicationUid=${applicationUid}&applicantUid=${application?.applicantUid}`
      );
    }
  };

  const navItems = React.useMemo(
    () =>
      links?.map((link) => (
        <a
          key={link.label}
          href={link.link}
          className={classes.link}
          data-active={active === link.link || undefined}
          onClick={(event) => {
            event.preventDefault();
            if (link.disabled) return;

            setMenuOpened(false);

            if (link.link === "/get-visa" && application) {
              setApplication!(null);
            }
            setActiveStep?.(
              link.link === "/get-visa" ? 0 : activeStep ? activeStep : -1
            );

            if (link.label === "Premium Services") {
              setShowPremiumServices((prev) => !prev);
            } else {
              setActive(link.link);
              navigate(link.link);
            }
          }}
          onContextMenu={(event) => event.preventDefault()}
          style={{
            padding: "10px 15px",
            textDecoration: "none",
            color: active === link.link ? primaryColor : "#333",
            fontWeight: active === link.link ? "600" : "500",
            transition: "color 0.3s ease-in-out",
          }}
        >
          {link.label}
          {link.label === "Premium Services" && (
            <span style={{ marginLeft: 5, verticalAlign: "middle" }}>
              {link.icon}
            </span>
          )}
        </a>
      )),
    [active, primaryColor, application, navigate, setActiveStep]
  );

  const mobNavItems = React.useMemo(
    () =>
      mobileLinks?.map((link) => (
        <a
          key={link.label}
          href={link.link}
          className={classes.link}
          data-active={active === link.link || undefined}
          onClick={(event) => {
            event.preventDefault();
            if (link.disabled) return;

            setMenuOpened(false);

            if (link.link === "/get-visa" && application) {
              setApplication!(null);
            }
            setActiveStep?.(
              link.link === "/get-visa" ? 0 : activeStep ? activeStep : -1
            );

            if (link.label === "Logout") {
              logoutCurrentUser!();
            } else {
              setActive(link.link);
              navigate(link.link);
            }
          }}
          onContextMenu={(event) => event.preventDefault()}
          style={{
            padding: "10px 15px",
            textDecoration: "none",
            color:
              link?.label.toLowerCase() === "logout"
                ? "#D72525"
                : active === link.link
                  ? primaryColor
                  : "#333",
            fontWeight: active === link.link ? "600" : "500",
            transition: "color 0.3s ease-in-out",
          }}
        >
          {link.label}
        </a>
      )),
    [active, primaryColor, application, navigate, setActiveStep]
  );

  const handleStepClick = (index: number) => {
    if (activeStep == null || activeStep < 0) return;

    const stepLink = applicationSteps[index].link;

    const isDraft =
      (application?.status?.toLowerCase()?.includes("draft") ||
        application?.status?.toLowerCase()?.includes("initiated")) ??
      true;

    const navigateWithUid = (path: string) => {
      navigate(
        applicationUid ? `${path}?applicationUid=${applicationUid}` : path
      );
    };

    switch (stepLink) {
      case "/document-playground":
        if (isDraft && applicationUid) {
          navigate(
            `/document-playground?applicationUid=${application?.applicationUid}&applicantUid=${application?.applicantUid}`
          );
        }
        break;
      case "/form":
        if (
          applicationUid &&
          application?.docIds &&
          application?.docIds?.length > 0
        ) {
          navigate(
            `/form?applicationUid=${applicationUid}&applicantUid=${application?.applicantUid}`
          );
        }
        break;
      case "/visa-requirements":
      case "/get-visa":
        if (category && fromCountry && toCountry && isDraft) {
          navigateWithUid(stepLink);
        }
        break;
      case "/summary":
        if (!isDraft && applicationUid) {
          navigateWithUid(stepLink);
        }
        break;
      default:
        break;
    }
  };

  const handleUpdateApplication = useCallback(
    async (value: [Date | null, Date | null]) => {
      value[0] ? setFromDate!(value[0]) : "";
      value[1] ? setToDate!(value[1]) : "";
      const applicationToUpdate: IApplication = {
        ...application!,
        visaDetails: {
          ...application?.visaDetails!,
          fromDate: value[0] ? format(value[0], "dd/MM/yyyy") : "",
          toDate: value[1] ? format(value[1], "dd/MM/yyyy") : "",
        },
      };
      await applicationService
        .updateApplication(applicationUid!, applicationToUpdate)
        .then(async (res: any) => {
          if (res?.response) setApplication!(res?.response);
        });
    },
    [application, applicationUid, setApplication, setFromDate, setToDate]
  );

  const toggleMenu = () => setMenuOpened((prev) => !prev);

  const HeaderTopWrapper = React.memo(() => (
    <Box
      style={{
        width: "100%",
        padding: "10px 16px",
        borderBottom: "",
        display:
          deviceType === "Mobile" || deviceType === "Tablet" ? "none" : "block",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px",
            padding:
              deviceType === "Mobile" || deviceType === "Tablet"
                ? "0px"
                : "0px 50px",
            display: "flex",
          }}
        >
          <>
            <Box style={{ flex: 1, minWidth: "300px" }}>
              <UserWelcomeSection userName={user?.name || "-"} />
            </Box>
            {deviceType === "Desktop" ? (
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <VisaDetailsActionBar
                  backgroundColor="rgba(155, 155, 155, 0.08)"
                  country={toCountry?.country || ""}
                  fromDate={fromDate}
                  toDate={toDate}
                  purpose={
                    //@ts-ignore
                    visaTypes[application?.visaDetails?.visaType] ??
                    category ??
                    ""
                  }
                  onChangeClick={(value: [Date | null, Date | null]) =>
                    handleUpdateApplication!(value)
                  }
                  disabledDateChange={application?.status
                    ?.toLowerCase()
                    ?.includes("form-data")}
                />
              </Box>
            ) : null}
          </>
        </Box>

        <Box
          style={{
            width: "53%",
            minWidth: "480px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            alignItems: "center",
          }}
        >
          <Box className={classes.headerTopStepper}>
            <ApplicationProgressStepper
              handleStepClick={handleStepClick}
              steps={applicationSteps}
              primaryColor={primaryColor}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ));

  const HeaderTopCenteredWrapper = React.memo(() => (
    <Box className={classes.headerTopCenteredWrapper}>
      <Box className={classes.headerTopContent}>
        <Box
          className={classes.headerTopCentered}
          style={{
            textAlign: "center",
            width: "100%",
            padding:
              deviceType === "Mobile" || deviceType === "Tablet"
                ? "0 15px"
                : "0",
          }}
        >
          <UserWelcomeSection userName={user?.name || "-"} />
        </Box>

        <Box
          className={classes.headerTopStepperCentered}
          pt={24}
          style={{
            display:
              deviceType === "Mobile" || deviceType === "Tablet"
                ? "none"
                : "block",
          }}
        >
          <ApplicationProgressStepper
            handleStepClick={handleStepClick}
            steps={applicationSteps}
          />
        </Box>
      </Box>
    </Box>
  ));

  return (
    <Box
      ref={headerRef}
      style={{
        marginBottom: "15px",
        position: "static", // Ensure header is in normal document flow
      }}
    >
      <Box className={classes.headerContainer}>
        <div>
          <Box
            className={classes.inner}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
              minHeight: "40px",
              width: "100%",
              padding:
                deviceType === "Mobile" || deviceType === "Tablet"
                  ? "0 10px"
                  : "0 20px",
              position: "relative", // Make sure not fixed or sticky
            }}
          >
            {(deviceType === "Mobile" || deviceType === "Tablet") && (
              <Box onClick={handleBackClick}>
                <img src={ICON_BACK} alt="Back button" title="Go back" />
              </Box>
            )}

            <Box className={classes.logoContainer} style={{ flex: "0 0 auto" }}>
              {logoUrl ? (
                <Box
                  className={classes.logoPoweredByContainer}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img
                    src={`data:image/png;base64,${logoUrl}`}
                    alt="Company Logo"
                    className={
                      deviceType === "Desktop"
                        ? classes.logo
                        : classes.mobileLogo
                    }
                  />

                  {deviceType === "Desktop" && (
                    <Text
                      className={classes.poweredByText}
                      component="span"
                      aria-label="Powered by Nuron.AI"
                    >
                      POWERED BY NURON.AI
                    </Text>
                  )}
                </Box>
              ) : (
                <IconUserCircle size={30} aria-label="Default user icon" />
              )}
            </Box>
            {deviceType === "Desktop" && (
              <Box style={{ display: "flex", fontSize: "14px" }}>
                {navItems}
                <div>
                  <Menu
                    width={260}
                    position="bottom-end"
                    transitionProps={{ transition: "pop-top-right" }}
                    onClose={() => setUserMenuOpened(false)}
                    onOpen={() => {
                      setUserMenuOpened(true);
                      setShowPremiumServices(false);
                    }}
                    withinPortal
                  >
                    <Menu.Target>
                      <UnstyledButton
                        style={{
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "inherit",
                          },
                        }}
                        className={cx(classes.user, {
                          [classes.userActive]: userMenuOpened,
                        })}
                      >
                        <Group gap={7} mt={2} ml={4}>
                          <Avatar radius="xl" size={30}>
                            {user?.name?.charAt(0)?.toUpperCase()}
                          </Avatar>
                          <IconChevronDown size={12} stroke={1.5} />
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown
                      style={{
                        background: "rgba(155, 155, 155, 0.08)",
                        backdropFilter: "blur(8px)",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                        padding: "12px",
                        borderRadius: "12px",
                        position: "relative",
                        top: "6.5rem",
                      }}
                    >
                      <Menu.Item
                        classNames={{ item: "menu-item-custom" }}
                        onClick={() => navigate("/profile")}
                      >
                        My Profile
                      </Menu.Item>

                      <Menu.Item
                        classNames={{ item: "menu-item-custom" }}
                        leftSection={<IconLogout size={20} stroke={1.5} />}
                        onClick={logoutCurrentUser}
                      >
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </Box>
            )}

            {(deviceType === "Mobile" || deviceType === "Tablet") && (
              <Box onClick={toggleMenu}>
                <img
                  src={MENU_ICON}
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  alt="Menu toggle"
                />
              </Box>
            )}
          </Box>

          {menuOpened && (
            <Box
              className={classes.mobileMenu}
              hiddenFrom="lg"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Drawer
                opened={menuOpened}
                onClose={toggleMenu}
                position="right"
                size={330}
                overlayProps={{ opacity: 0, blur: 0 }}
                styles={{
                  content: {
                    background: "rgba(155, 155, 155, 0.1)",
                    WebkitBackdropFilter: "blur(32px)",
                    backdropFilter: "blur(32px)",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderImageSource:
                      "linear-gradient(179deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.04) 52%, rgba(255, 255, 255, 0) 73%, rgba(255, 255, 255, 0.4) 127%)",
                    borderImageSlice: "1",
                  },
                  header: {
                    display: "block",
                    background: "rgba(155, 155, 155, 0.1)",
                    WebkitBackdropFilter: "blur(32px)",
                    backdropFilter: "blur(32px)",
                    overflow: "hidden",
                  },
                  body: {
                    height: "90%",
                    overflow: "hidden",
                    position: "relative",
                    paddingBottom: "120px",
                  },
                }}
              >
                <Accordion
                  value={activeAccordion}
                  onChange={setActiveAccordion}
                  style={{ marginTop: "5px" }}
                  chevron={<IconChevronDown size={16} />}
                  styles={{
                    control: {
                      position: "relative",
                      borderRadius: "10px",
                      backgroundColor: "rgba(155, 155, 155, 0.08)",
                    },
                    chevron: {
                      "&[data-rotate]": { transform: "rotate(-180deg)" },
                    },
                    content: {
                      width: "296px",
                    },
                  }}
                >
                  {application ||
                  (fromDate &&
                    toDate &&
                    category &&
                    fromCountry &&
                    toCountry) ? (
                    <Accordion.Item value="travel-details">
                      <Accordion.Control>
                        <Text fw={500} size="sm">
                          Travel Details
                        </Text>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <VisaDetailsActionBarMobile
                          country={toCountry?.country || ""}
                          fromDate={fromDate}
                          toDate={toDate}
                          purpose={
                            //@ts-ignore
                            visaTypes[application?.visaDetails?.visaType] ??
                            category ??
                            ""
                          }
                          onChangeClick={(value: [Date | null, Date | null]) =>
                            handleUpdateApplication!(value)
                          }
                          disabledDateChange={application?.status
                            ?.toLowerCase()
                            ?.includes("form-data")}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  ) : null}

                  <Accordion.Item value="premium-services">
                    <Accordion.Control>
                      <Text fw={500} size="sm">
                        Premium Services
                      </Text>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text
                        fw={600}
                        size="sm"
                        style={{
                          padding: "10px 8px",
                          color: "#D72525",
                          fontWeight: "600",
                          transition: "color 0.3s ease-in-out",
                        }}
                      >
                        Coming Soon
                      </Text>
                      <Text
                        fw={500}
                        size="sm"
                        style={{
                          padding: "10px 15px",
                          color: "#333",
                          fontWeight: "600",
                          transition: "color 0.3s ease-in-out",
                        }}
                      >
                        Assign to Visa Staff
                      </Text>
                      <Text
                        fw={500}
                        size="sm"
                        style={{
                          padding: "10px 15px",
                          color: "#333",
                          fontWeight: "600",
                          transition: "color 0.3s ease-in-out",
                        }}
                      >
                        Get Insurance & Flight
                      </Text>
                      <Text
                        fw={500}
                        size="sm"
                        style={{
                          padding: "10px 15px",
                          color: "#333",
                          fontWeight: "600",
                          transition: "color 0.3s ease-in-out",
                        }}
                      >
                        We'll Write Your Cover Letter
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <Box className={classes.mobileMenuContent}>{mobNavItems}</Box>
                <Box
                  style={{
                    padding: "15px",
                    textAlign: "left",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={ICON_SUPPORT}
                        alt="Support Icon"
                        title="Support"
                        width={20}
                        height={20}
                        style={{
                          filter:
                            "brightness(0) saturate(100%) invert(40%) sepia(23%) saturate(5108%) hue-rotate(1deg) brightness(100%) contrast(105%)",
                        }}
                      />

                      <Text fw={500} size="sm" style={{ color: "#FD5B00" }}>
                        Support
                      </Text>
                    </Box>
                    <Text
                      fw={500}
                      size="sm"
                      style={{
                        color: "#333",
                        fontWeight: "600",
                        transition: "color 0.3s ease-in-out",
                        fontSize: "12px",
                      }}
                    >
                      Contact: +91 (555) 000-0000
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        color: "#333",
                        fontWeight: "600",
                        transition: "color 0.3s ease-in-out",
                        fontSize: "12px",
                      }}
                    >
                      Email:{" "}
                      <a
                        href="mailto:sudeepa@nuronai.org"
                        style={{
                          color: "#333",
                          fontWeight: "600",
                          transition: "color 0.3s ease-in-out",
                          fontSize: "12px",
                        }}
                      >
                        sudeepa@nuronai.org
                      </a>
                    </Text>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          )}
        </div>

        {showPremiumServices && (
          <div
            ref={premiumServiceRef}
            className={classes.premiumServiceCardGrid}
            onMouseLeave={() => setShowPremiumServices(false)}
          >
            <PremiumServiceCardGrid />
          </div>
        )}
      </Box>

      {activeStep !== -1 && (
        <>
          {activeStep === 0 && location.href?.includes("get-visa") ? (
            <HeaderTopCenteredWrapper />
          ) : (
            (application ||
              (fromDate && toDate && category && fromCountry && toCountry)) && (
              <HeaderTopWrapper />
            )
          )}
        </>
      )}
    </Box>
  );
});
