import React from "react";
import BG from "../../../assets/background.png";
import Background from "../../../assets/background1.png";
const BackgroundScreen: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <div style={styles.container}>
      {/* <img src={Background} alt="Background" style={styles.img} /> */}
      <div style={styles.content}>{children}</div>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed" as "fixed",
    width: "100vw",
    height: "100vh",
    overflow: "hidden" as "hidden",
    backgroundImage: `url(${Background})`,
    backgroundSize: "200% 200%",
    backgroundPosition: "left 70% top 50%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover" as "cover",
    zIndex: 1,
  },
  content: {
    position: "relative" as "relative",
    zIndex: 2,
    width: "100%",
    height: "100%",
    overflowY: "auto" as "auto",
    boxSizing: "border-box" as "border-box",
  },
};

export default BackgroundScreen;
