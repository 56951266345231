export const DEFAULT_CHAR_LIMIT = 20;

/**
 * Truncate text to a certain limit
 * @param text
 * @param limit
 * @returns
 */
export const truncateText = (
  text: string,
  limit: number = DEFAULT_CHAR_LIMIT
) => {
  return text.length > limit ? text.slice(0, limit) + "..." : text;
};

export const getPrimaryPassportDetails = (extractedData: any) => {
  let passportFields: any;

  passportFields = Object.values(
    extractedData?.data?.processedFieldsByCategory?.Passport || {}
  ).filter((passport: any) => passport?.isVerifiedByAgent)?.[0];

  if (!passportFields) {
    passportFields = Object.values(
      extractedData?.data?.processedFieldsByCategory?.Passport || {}
    ).filter((passport: any) => passport?.isPrimaryRecommended)?.[0];
  }

  if (!passportFields) {
    passportFields = Object.values(
      extractedData?.data?.processedFieldsByCategory?.Passport || {}
    )?.[0];
  }

  return passportFields;
};

/**
 * Function to check if the user role is in the roles array.
 * @param roles
 * @param userRole
 * @returns
 */
export const isInRole = (roles: string[], userRoles: string[]) => {
  return roles.some((role) => userRoles?.includes(role));
};

export const normalizeString = (pageName: string) => {
  if (!pageName) return "";
  let cleaned = pageName
    ?.replace(/[_-]/g, " ")
    ?.replace(/[^a-zA-Z0-9.\s]/g, "")
    ?.trim();

  let words = cleaned?.toLowerCase()?.split(".");

  if (words.length > 2) {
    return (
      words[words.length - 1]?.charAt(0)?.toUpperCase() +
      words[words.length - 1].slice(1)
    );
  } else if (words[0]?.includes("List of people")) {
    return (
      words[words.length - 1]?.charAt(0)?.toUpperCase() +
      words[words.length - 1].slice(1)
    );
  }

  if (words?.length > 0) {
    words[0] = words?.[0]?.charAt(0)?.toUpperCase() + words?.[0]?.slice(1);
  }

  return words?.join(" ");
};
