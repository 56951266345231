/* eslint-disable @typescript-eslint/no-explicit-any */

import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IV_TOKEN,
  LAST_LOGOUT_TIME,
  REALM_CLIENT_ID,
} from "../constants/storage.constants";

/**
 * Api handler class to handle all api calls
 * @class ApiHandler
 */
class ApiHandler {
  private baseUrl = import.meta.env.VITE_DATA_SERVICE_API_URL;

  private handleVoidParams = (params?: any) => {
    if (params) {
      for (const key in params) {
        if (
          params[key] == null ||
          params[key] === undefined ||
          params[key] === ""
        ) {
          delete params[key];
        }
      }
    }
    return params;
  };

  get = async <T = any>(
    endpoint: string,
    params?: any,
    realmClientId?: string
  ) => {
    try {
      const options = await this.axiosOptionsConfig(
        "application/json",
        realmClientId
      );
      const response = await Axios.get<T>(endpoint, {
        params: this.handleVoidParams(params),
        ...options,
      });

      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  download = async <T = any>(endpoint: string, params?: any) => {
    try {
      const options = await this.axiosOptionsConfig();
      const response = await Axios.get<T>(endpoint, {
        params: this.handleVoidParams(params),
        ...options,
        responseType: "blob",
      });

      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  post = async <T = any>(endpoint: string, body: any, contentType?: string) => {
    try {
      const options = await this.axiosOptionsConfig(contentType);
      const response = await Axios.post<T>(endpoint, body, options);
      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  postFormData = async <T = any>(endpoint: string, body: any) => {
    try {
      const options = await this.axiosOptionsConfig("multipart/form-data");
      const response = await Axios.postForm<T>(endpoint, body, options);
      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  put = async <T = any>(endpoint: string, body: any, contentType?: string) => {
    try {
      const options = await this.axiosOptionsConfig(contentType);
      const response = await Axios.put<T>(endpoint, body, options);
      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  delete = async <T = any>(endpoint: string, params?: any) => {
    try {
      const options = await this.axiosOptionsConfig();
      const response = await Axios.delete<T>(endpoint, {
        params: this.handleVoidParams(params),
        ...options,
      });
      const data = this.handleApiResponse<T>(response);
      return data;
    } catch (error: any) {
      if (error) {
        this.handleApiResponse(error?.response || error);
      }
    }
  };

  logout = () => {
    localStorage.setItem(LAST_LOGOUT_TIME, new Date().toISOString());
    window.location.reload();
  };

  handleApiResponse = <T = any>(response: AxiosResponse<T>) => {
    if (
      [401, 403].indexOf(response.status) !== -1 &&
      !response?.config?.url?.includes("auth")
    ) {
      this.logout();
      console.warn("Logged Out Due to Error url: ", response.config.url);
    } else if (
      response.status === 500 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 401
    ) {
      throw response?.data;
    }

    return response?.data;
  };

  getBearerToken = () => {
    const authKey = localStorage.getItem(IV_TOKEN);
    return `Bearer ${authKey}`;
  };

  getRealmClientId = () => {
    const realmClientId = localStorage.getItem(REALM_CLIENT_ID);
    return realmClientId ?? import.meta.env.VITE_KEYCLOAK_CLIENT_ID;
  };

  getHeader = async (contentType?: string, realmClientId?: string) => {
    const header = {
      "Content-Type": contentType || "application/json",
      Accept: "application/json",
      Authorization: this.getBearerToken(),
      "realm-client-id": realmClientId ?? this.getRealmClientId(),
    };

    return header;
  };

  axiosOptionsConfig = async (
    contentType?: string,
    realmClientId?: string
  ): Promise<AxiosRequestConfig> => {
    const header = await this.getHeader(contentType, realmClientId);
    return new Promise<AxiosRequestConfig>((resolve) => {
      resolve({
        baseURL: this.getApiBaseUrl(),
        headers: header,
      });
    });
  };

  getApiBaseUrl = () => {
    return this.baseUrl;
  };
}

export default new ApiHandler();
