import { Button, Text, Box, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import { differenceInDays, parse, isValid } from "date-fns";
import { DatePickerInput, DateFormatter } from "@mantine/dates";
import LocationIcon from "../../../assets/location.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import ClockIcon from "../../../assets/clock.svg";
import VisitorIcon from "../../../assets/visitor.svg";
import { useApp } from "../../../contexts/AppContext";
import { IconChevronRight } from "@tabler/icons-react";

interface VisaDetailsActionBarPropsMobile {
  country: string;
  purpose: string;
  toDate: Date | null | undefined;
  fromDate: Date | null | undefined;
  onChangeClick?: (value: [Date | null, Date | null]) => void;
  iconColor?: string;
  disabledDateChange?: boolean;
}

export function VisaDetailsActionBarMobile({
  country,
  purpose,
  fromDate,
  toDate,
  onChangeClick,
  iconColor = "rgba(20, 24, 31, 0.8)",
  disabledDateChange,
}: VisaDetailsActionBarPropsMobile) {
  const [value, setValue] = useState<[Date | null, Date | null]>([
    fromDate ?? null,
    toDate ?? null,
  ]);

  const parseDate = (dateStr: string | undefined | Date): Date | null => {
    if (!dateStr) return null;
    if (dateStr instanceof Date) {
      return isValid(dateStr) ? dateStr : null;
    }

    const parsedFromFormat = parse(dateStr, "dd/MM/yyyy", new Date());
    if (isValid(parsedFromFormat)) {
      return parsedFromFormat;
    }

    const dateObj = new Date(dateStr);
    return isValid(dateObj) ? dateObj : null;
  };

  const theme = useMantineTheme();

  const { deviceType } = useApp();
  const primaryColor = theme.colors[theme.primaryColor][6];

  const formatter: DateFormatter = ({ type, date, locale }) => {
    if (type === "range" && Array.isArray(date)) {
      return date
        .filter(Boolean)
        .map((d) =>
          d?.toLocaleDateString(locale, {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        )
        .join(" - ");
    }
    return "";
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        justifyContent: "space-evenly",
        backgroundColor: "rgba(155, 155, 155, 0.08)",
        padding: "15px",
        borderRadius: "16px",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img
          src={LocationIcon}
          alt=""
          style={{
            width: 18,
            height: 18,
            marginLeft: "2px",
            marginRight: "12px",
            filter: `invert(${iconColor})`,
          }}
        />
        <Text fw={500} size="sm">
          {" "}
          {country}
        </Text>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img
          src={VisitorIcon}
          alt=""
          style={{
            width: 18,
            height: 18,
            marginLeft: "2px",
            marginRight: "12px",
            filter: `invert(${iconColor})`,
          }}
        />
        <Text fw={500} size="sm">
          {purpose}
        </Text>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img
          src={ClockIcon}
          alt=""
          style={{
            width: 18,
            height: 18,
            marginLeft: "2px",
            marginRight: "12px",
            filter: `invert(${iconColor})`,
          }}
        />
        <Text fw={500} size="sm">
          {differenceInDays(
            parseDate(toDate!) || new Date(),
            parseDate(fromDate!) || new Date()
          )}{" "}
          days
        </Text>
      </Box>

      <DatePickerInput
        variant="unstyled"
        leftSection={
          <img
            src={CalendarIcon}
            alt=""
            style={{
              width: 16,
              height: 16,
              marginRight: "12px",
              filter: `invert(${iconColor})`,
            }}
          />
        }
        rightSection={
          <IconChevronRight
            color="black"
            style={{
              marginLeft: "20px",
            }}
          />
        }
        style={{
          color: "white",
          fontWeight: 500,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "15px",
          marginTop: "-6px",
        }}
        minDate={new Date()}
        type="range"
        value={value}
        onChange={setValue}
        valueFormatter={formatter}
        disabled={disabledDateChange}
      />

      {value[0] &&
        value[1] &&
        (value[0] !== fromDate || value[1] !== toDate) && (
          <Button
            variant="default"
            onClick={() => onChangeClick!(value)}
            styles={{
              root: {
                background: primaryColor,
                backdropFilter: "blur(10px)",
                border: "1px solid white",
                color: "white",
                borderRadius: "20px",
                height:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "32px"
                    : "36px",
                padding: "0 16px",
                minWidth:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "80px"
                    : "100px",
                flexShrink: 0,
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.1)",
                },
              },
            }}
          >
            Update
          </Button>
        )}
    </Box>
  );
}
