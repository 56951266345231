import { IApplication, IVisaDetails } from "../types/application.types";
import { IReview } from "../types/review.types";
import apiUtil from "../utils/api.util";

class applicationService {
  route = "/application";

  getApplicationByUid = async (applicationUid: string): Promise<any> => {
    const response = await apiUtil.get(
      `${this.route}/?applicationUid=${applicationUid}`
    );
    return response;
  };

  updateApplication = async (
    applicationUid: string,
    application: IApplication
  ): Promise<void> => {
    const response = await apiUtil.post(
      `${this.route}/update?applicationUid=${applicationUid}`,
      { application }
    );
    return response;
  };

  getApplicationsByPassportId = async (passportId: string): Promise<void> => {
    const response = await apiUtil.get(
      `${this.route}/get-applicants-byPassportId?passportId=${passportId}`,
      {}
    );
    return response;
  };

  getApplicationsByAgencyId = async (agencyUid: string): Promise<void> => {
    const response = await apiUtil.get(
      `${this.route}/applications-by-agencyUid?agencyUid=${agencyUid}`,
      {}
    );
    return response;
  };

  getApplicationsByAgentUid = async (agentUid: string): Promise<void> => {
    const response = await apiUtil.get(
      `${this.route}/application-by-agentUid?agentUid=${agentUid}`,
      {}
    );
    return response;
  };

  transferApplication = async (
    applicationUid: string,
    agentUid: string,
    applicantUid: string
  ): Promise<void> => {
    const response = await apiUtil.post(
      `${this.route}/transfer-to-agent?agentUid=${agentUid}&applicationUid=${applicationUid}&applicantUid=${applicantUid}`,
      {}
    );
    return response;
  };

  getApplicationsByAgentAndApplicantUid = async (
    userId: string,
    applicantUid: string
  ): Promise<any> => {
    const response = await apiUtil.get(
      `${this.route}/get-by-agent-applicant-Uid?agentUid=${userId}&applicantUid=${applicantUid}`
    );
    return response;
  };

  getApplicationsByApplicantUid = async (
    applicantUid: string
  ): Promise<any> => {
    const response = await apiUtil.get(
      `${this.route}/get-by-applicantUid?applicantUid=${applicantUid}`
    );
    return response;
  };

  getApplicationsByAgencyIdAndApplicantUid = async (
    userId: string,
    applicantUid: string
  ): Promise<any> => {
    const response = await apiUtil.get(
      `${this.route}/get-by-agency-applicant-Uid?agencyUid=${userId}&applicantUid=${applicantUid}`
    );
    return response;
  };

  draftApplication = async (
    applicantUid: string,
    agencyUid: string,
    visaDetails: IVisaDetails,
    agentId?: string,
    applicationCreationKey?: string
  ): Promise<void> => {
    const response = await apiUtil.post(`${this.route}/draft-application`, {
      applicantUid,
      agentId,
      agencyUid,
      visaDetails,
      applicationCreationKey,
    });
    return response;
  };

  deleteApplication = async (applicationUid: string): Promise<any> => {
    const response = await apiUtil.post(
      `${this.route}/delete?applicationUid=${applicationUid}`,
      {}
    );
    return response;
  };

  getApplicationsByReviewerUid = async (userId: string): Promise<any> => {
    const response = await apiUtil.get(
      `${this.route}/get-applications-by-reviewerUid?reviewerUid=${userId}`,
      {}
    );
    return response;
  };

  sendApplicationForReview = async (
    reviewerUids: string[],
    applicationUid: string
  ): Promise<any> => {
    const response = await apiUtil.post(
      `${this.route}/send-application-for-review?applicationUid=${applicationUid}`,
      { reviewerUids }
    );
    return response;
  };

  reviewApplication = async (
    review: Partial<IReview>,
    applicationUid: string
  ): Promise<any> => {
    const response = await apiUtil.post(
      `${this.route}/add-application-review`,
      {
        applicationUid,
        review,
      }
    );
    return response;
  };
}

export default new applicationService();
