import axios from "axios";
import apiUtil from "../utils/api.util";

class AuthService {
  private baseUrl = import.meta.env.VITE_DATA_SERVICE_API_URL + "/auth";

  validateUserToken = async (accessToken: string) => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.baseUrl}/validate-token`,
      {
        accessToken,
      },
      {
        headers: apiHeaders,
      }
    );
    return response?.data;
  };

  login = async (username: string, password: string, newPassword: string) => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.baseUrl}/login`,
      {
        username,
        password,
        newPassword,
      },
      {
        headers: apiHeaders,
      }
    );
    return response?.data;
  };

  logout = async (refreshToken: string) => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.baseUrl}/logout`,
      {
        refreshToken,
      },
      {
        headers: apiHeaders,
      }
    );
    return response?.data;
  };

  refreshToken = async (refreshToken: string) => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.baseUrl}/refresh-token`,
      {
        refreshToken,
      },
      {
        headers: apiHeaders,
      }
    );
    return response?.data;
  };

  forgotPassword = async (email: string) => {
    const apiHeaders = await apiUtil.getHeader();
    const response = await axios.post(
      `${this.baseUrl}/forgot-password`,
      {
        email,
      },
      {
        headers: apiHeaders,
      }
    );
    return response?.data;
  };
}

export default new AuthService();
