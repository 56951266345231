import React, { useEffect, useMemo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { ICountry } from "../types/visaRequirements.type";
import { IApplication } from "../types/application.types";
import applicationService from "../services/application.service";
import { DateValue } from "@mantine/dates";
import documentService from "../services/document.service";
import { IDocumentData } from "../types/document.types";
import { parse, format } from "date-fns";
import {
  getCountryByAlpha3,
  getCountryByLabel,
  getFromCountryByAlpha3,
  getToCountryByAlpha3,
  SUPPORTED_FROM_COUNTRIES,
  SUPPORTED_TO_COUNTRIES,
} from "../constants/supportedCountries.constants";
import { useUser } from "./UserContext";

interface IApplicationContextProps {
  activeStep: number;
  fromCountry: ICountry;
  toCountry: ICountry;
  toDate: Date | null;
  fromDate: Date | null;
  category: string | null;
  nationality: ICountry;
  applicantUid: string;
  applicationUid: string;
  application: IApplication | null;

  visaType: string | null;
  visaDuration: string | null;
  applicationCreationKey: string | null;
  premiumVerificationKey: string | null;

  setActiveStep: (index: number) => void;
  setFromDate: (fromDate: DateValue) => void;
  setToDate: (toDate: DateValue) => void;
  setCategory: (category: string) => void;
  setFromCountry: (fromCountry: ICountry) => void;
  setNationality: (nationality: ICountry) => void;
  setToCountry: (toCountry: ICountry) => void;
  documents: IDocumentData[];
  setApplication: (application: IApplication | null) => void;
}

interface IApplicationContextState {
  activeStep: number;
  fromCountry: ICountry;
  toCountry: ICountry;
  toDate: Date | null;
  fromDate: Date | null;
  category: string | null;
  nationality: ICountry;
  application: IApplication | null;
  applicantUid: string;
  documents: IDocumentData[];

  visaType: string | null;
  visaDuration: string | null;
  applicationCreationKey: string | null;
  premiumVerificationKey: string | null;
}

type IApplicationContextStateActions =
  | { type: "active-step"; activeStep: number }
  | { type: "application"; application: IApplication | null }
  | { type: "fromDate"; fromDate: Date | null }
  | { type: "toDate"; toDate: Date | null }
  | { type: "category"; category: string | null }
  | { type: "nationality"; nationality: ICountry }
  | { type: "fromCountry"; fromCountry: ICountry }
  | { type: "documents"; documents: IDocumentData[] }
  | { type: "toCountry"; toCountry: ICountry }
  | { type: "visaType"; visaType: string | null }
  | { type: "visaDuration"; visaDuration: string | null }
  | { type: "applicationCreationKey"; applicationCreationKey: string | null }
  | { type: "premiumVerificationKey"; premiumVerificationKey: string | null };

const steps = [
  "/get-visa",
  "/visa-requirements",
  "/document-playground",
  "/form",
  "/summary",
];

const ApplicationContextStateReducer: React.Reducer<
  IApplicationContextState,
  IApplicationContextStateActions
> = (state, action) => {
  switch (action.type) {
    case "active-step":
      return { ...state, activeStep: action.activeStep };
    case "application":
      return { ...state, application: action.application };
    case "fromDate":
      return { ...state, fromDate: action.fromDate };
    case "toDate":
      return { ...state, toDate: action.toDate };
    case "category":
      return { ...state, category: action.category };
    case "nationality":
      return { ...state, nationality: action.nationality };
    case "fromCountry":
      return { ...state, fromCountry: action.fromCountry };
    case "toCountry":
      return { ...state, toCountry: action.toCountry };
    case "documents":
      return { ...state, documents: action.documents };
    case "visaType":
      return { ...state, visaType: action.visaType };
    case "visaDuration":
      return { ...state, visaDuration: action.visaDuration };
    case "applicationCreationKey":
      return {
        ...state,
        applicationCreationKey: action.applicationCreationKey,
      };
    case "premiumVerificationKey":
      return {
        ...state,
        premiumVerificationKey: action.premiumVerificationKey,
      };

    default:
      return state;
  }
};

const ApplicationContext = React.createContext<
  Partial<IApplicationContextProps>
>({});

export const ApplicationProvider = (props: any) => {
  const { currentUserProfile } = useUser();

  const ApplicationState: IApplicationContextState = {
    activeStep: -1,
    fromCountry: { country: "", countryISO: "" },
    toCountry: { country: "", countryISO: "" },
    toDate: null,
    fromDate: null,
    category: null,
    nationality: getCountryByLabel(currentUserProfile?.address?.country!),
    applicantUid: "",
    application: null,
    documents: [],
    visaType: null,
    visaDuration: null,
    applicationCreationKey: null,
    premiumVerificationKey: null,
  };

  const [state, dispatch] = useReducer(
    ApplicationContextStateReducer,
    ApplicationState
  );

  const [searchParams] = useSearchParams();
  const memoizedSearchParams = useMemo(
    () => searchParams.toString(),
    [searchParams]
  );

  const applicationUid = new URLSearchParams(memoizedSearchParams).get(
    "applicationUid"
  )
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("applicationUid")!
      )
    : null;

  const applicantUid = new URLSearchParams(memoizedSearchParams).get(
    "applicantUid"
  )
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("applicantUid")!
      )
    : new URLSearchParams(memoizedSearchParams).get("agencyUid")
      ? decodeURIComponent(
          new URLSearchParams(memoizedSearchParams).get("agencyUid")!
        )
      : null;

  const category = (() => {
    const categoryParam =
      new URLSearchParams(memoizedSearchParams).get("category") ||
      new URLSearchParams(memoizedSearchParams).get("visaCategory");
    return categoryParam ? decodeURIComponent(categoryParam) : null;
  })();

  const fromDate = (() => {
    const fromDateParam = new URLSearchParams(memoizedSearchParams).get(
      "fromDate"
    );
    return fromDateParam ? new Date(decodeURIComponent(fromDateParam)) : null;
  })();

  const toDate = (() => {
    const toDateParam = new URLSearchParams(memoizedSearchParams).get("toDate");
    return toDateParam ? new Date(decodeURIComponent(toDateParam)) : null;
  })();

  const fromCountry = (() => {
    const fromCountryParam =
      new URLSearchParams(memoizedSearchParams).get("fromCountry") ||
      new URLSearchParams(memoizedSearchParams).get("sourceCountry");
    const decoded = decodeURIComponent(fromCountryParam!);

    if (decoded.includes("country") && decoded.includes("countryISO")) {
      const country = JSON.parse(decoded);
      return {
        country: country.country,
        countryISO: country.countryISO,
      };
    }

    const res = getFromCountryByAlpha3(decoded);
    return {
      country: res?.label,
      countryISO: res?.value!,
    };
  })();

  const toCountry = (() => {
    const toCountryParam =
      new URLSearchParams(memoizedSearchParams).get("toCountry") ||
      new URLSearchParams(memoizedSearchParams).get("destinationCountry");
    const decoded = decodeURIComponent(toCountryParam!);

    if (decoded.includes("country") && decoded.includes("countryISO")) {
      const country = JSON.parse(decoded);
      return {
        country: country.country,
        countryISO: country.countryISO,
      };
    }

    const res = getToCountryByAlpha3(decoded);
    return {
      country: res?.label,
      countryISO: res?.value!,
    };
  })();

  const nationality = (() => {
    const nationalityParam = new URLSearchParams(memoizedSearchParams).get(
      "nationality"
    );
    const decoded = decodeURIComponent(nationalityParam!);
    const res = getCountryByAlpha3(decoded);
    return {
      country: res?.label,
      countryISO: res?.value!,
    };
  })();

  const visaType = new URLSearchParams(memoizedSearchParams).get("visaType")
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("visaType")!
      )
    : null;

  const visaDuration = new URLSearchParams(memoizedSearchParams).get(
    "visaDuration"
  )
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("visaDuration")!
      )
    : null;

  const applicationCreationKey = new URLSearchParams(memoizedSearchParams).get(
    "applicationCreationKey"
  )
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("applicationCreationKey")!
      )
    : null;

  const premiumVerificationKey = new URLSearchParams(memoizedSearchParams).get(
    "premiumVerificationKey"
  )
    ? decodeURIComponent(
        new URLSearchParams(memoizedSearchParams).get("premiumVerificationKey")!
      )
    : null;

  const setActiveStep = (activeStep: number) =>
    dispatch({ type: "active-step", activeStep });
  const setCategory = (category: string) =>
    dispatch({ type: "category", category });
  const setFromDate = (fromDate: Date) =>
    dispatch({ type: "fromDate", fromDate });
  const setToDate = (toDate: Date) => dispatch({ type: "toDate", toDate });
  const setFromCountry = (fromCountry: ICountry) =>
    dispatch({ type: "fromCountry", fromCountry });
  const setNationality = (nationality: ICountry) =>
    dispatch({ type: "nationality", nationality });
  const setToCountry = (toCountry: ICountry) =>
    dispatch({ type: "toCountry", toCountry });
  const setDocuments = (documents: IDocumentData[]) =>
    dispatch({ type: "documents", documents });
  const setApplication = (application: IApplication) =>
    dispatch({ type: "application", application });

  useEffect(() => {
    let stepIndex = steps.findIndex((step) => location.href.includes(step));

    if (stepIndex > -1) {
      if (stepIndex === 2 && !applicationUid) {
        setActiveStep(-1);
      } else {
        setActiveStep(stepIndex);
      }
    } else setActiveStep(-1);
  }, [location.href]);

  useEffect(() => {
    const getDocumentsByDocIDs = async (
      docIds: string[],
      applicantUid: string
    ) => {
      if (docIds) {
        return documentService
          .getExtractedDocsByDocIds(docIds, applicantUid)
          .then((response: any) => {
            const resp: any = response?.response;

            const docs = resp?.map((doc: any) => ({
              id: doc?._id,
              ...doc?.documentData,
            }));

            if (docs) {
              setDocuments(docs);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    let docIds = state?.application?.docIds || [];

    if (!docIds || docIds.length === 0) {
      setDocuments([]);
    }

    if (applicantUid && docIds) {
      getDocumentsByDocIDs(docIds, applicantUid);
    }
  }, [state?.application]);

  useEffect(() => {
    if (applicationUid) {
      try {
        applicationService
          .getApplicationByUid(applicationUid as string)
          .then((res: any) => {
            if (res) {
              const resp: IApplication = res?.response;
              setApplication(resp);

              const fromDateStr = resp?.visaDetails?.fromDate;

              const parsedDate =
                fromDateStr && fromDateStr !== ""
                  ? parse(fromDateStr, "dd/MM/yyyy", new Date())
                  : new Date();
              setFromDate(parsedDate);

              const toDateStr = resp?.visaDetails?.toDate;

              const parsedToDate =
                toDateStr && toDateStr !== ""
                  ? parse(toDateStr, "dd/MM/yyyy", new Date())
                  : new Date();
              setToDate(parsedToDate);

              setFromCountry({
                country: resp?.visaDetails?.visaFrom,
                countryISO: SUPPORTED_FROM_COUNTRIES?.find(
                  (from) => from.label === resp?.visaDetails?.visaFrom
                )?.value!,
              });
              setToCountry({
                country: resp?.visaDetails?.visaTo,
                countryISO: SUPPORTED_TO_COUNTRIES?.find(
                  (from) => from.label === resp?.visaDetails?.visaTo
                )?.value!,
              });

              setNationality({
                country:
                  resp?.visaDetails?.nationality ??
                  resp?.visaDetails?.visaFrom ??
                  "India",
                countryISO: resp?.visaDetails?.nationality
                  ? SUPPORTED_FROM_COUNTRIES?.find(
                      (from) => from.label === resp?.visaDetails?.nationality
                    )?.value!
                  : SUPPORTED_FROM_COUNTRIES?.find(
                      (from) => from.label === resp?.visaDetails?.visaFrom
                    )?.value!,
              });

              setCategory(resp?.visaDetails?.visaCategory);
            }
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [applicationUid]);

  useEffect(() => {
    if (applicationCreationKey) {
      dispatch({ type: "applicationCreationKey", applicationCreationKey });
    }
  }, [applicationCreationKey]);

  return (
    <ApplicationContext.Provider
      value={{
        activeStep: state.activeStep,
        fromCountry:
          state.fromCountry?.countryISO !== ""
            ? state.fromCountry
            : fromCountry,
        toCountry:
          state.toCountry?.countryISO !== "" ? state.toCountry : toCountry,
        toDate: state.toDate ?? toDate,
        fromDate: state.fromDate ?? fromDate,
        category: state.category ?? category,
        nationality: state.nationality ?? nationality,
        application: state.application,
        applicantUid: state.applicantUid,
        documents: state.documents,
        applicationUid,
        visaType: state.visaType ?? visaType,
        visaDuration: state.visaDuration ?? visaDuration,
        applicationCreationKey:
          state.applicationCreationKey ?? applicationCreationKey,
        premiumVerificationKey:
          state.premiumVerificationKey ?? premiumVerificationKey,
        setActiveStep,
        setFromDate,
        setToDate,
        setCategory,
        setFromCountry,
        setNationality,
        setToCountry,
        setApplication,
      }}
      {...props}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useApplication = () => {
  const context = React.useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("useApplication() must be used inside ApplicationProvider");
  } else {
    return context;
  }
};
