import { Group, Button, Text, Box, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import { differenceInDays, parse, isValid } from "date-fns";
import { DatePickerInput, DateFormatter } from "@mantine/dates";
import LocationIcon from "../../../assets/location.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import ClockIcon from "../../../assets/clock.svg";
import VisitorIcon from "../../../assets/visitor.svg";
import { useApp } from "../../../contexts/AppContext";

interface VisaDetailsActionBarProps {
  country: string;
  purpose: string;
  toDate: Date | null | undefined;
  fromDate: Date | null | undefined;
  onChangeClick?: (value: [Date | null, Date | null]) => void;
  backgroundColor?: string;
  borderColor?: string;
  iconColor?: string;
  textColor?: string;
  dividerColor?: string;
  disabledDateChange?: boolean;
}

export function VisaDetailsActionBar({
  country,
  purpose,
  fromDate,
  toDate,
  onChangeClick,
  backgroundColor,
  iconColor = "rgba(20, 24, 31, 0.8)",
  textColor = "black",
  dividerColor = "white",
  disabledDateChange,
}: VisaDetailsActionBarProps) {
  const [value, setValue] = useState<[Date | null, Date | null]>([
    fromDate ?? null,
    toDate ?? null,
  ]);

  const parseDate = (dateStr: string | undefined | Date): Date | null => {
    if (!dateStr) return null;
    if (dateStr instanceof Date) {
      return isValid(dateStr) ? dateStr : null;
    }

    const parsedFromFormat = parse(dateStr, "dd/MM/yyyy", new Date());
    if (isValid(parsedFromFormat)) {
      return parsedFromFormat;
    }

    const dateObj = new Date(dateStr);
    return isValid(dateObj) ? dateObj : null;
  };

  const theme = useMantineTheme();

  const { deviceType } = useApp();
  const primaryColor = theme.colors[theme.primaryColor][6];

  const Divider = () => (
    <Box
      style={{
        width: "1px",
        height: "24px",
        backgroundColor: dividerColor,
      }}
    />
  );

  const textProps = {
    c: textColor,
    fw: 500,
    style: {
      fontSize:
        deviceType === "Mobile"
          ? "12px"
          : deviceType === "Tablet"
            ? "13px"
            : "15px",
      maxWidth:
        deviceType === "Mobile" || deviceType === "Tablet" ? "150px" : "200px",

      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const formatter: DateFormatter = ({ type, date, locale }) => {
    if (type === "range" && Array.isArray(date)) {
      if (deviceType === "Mobile" || deviceType === "Tablet") return "";
      return date
        ?.filter(Boolean)
        ?.map((d) =>
          d?.toLocaleDateString(locale, {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        )
        .join(" - ");
    }
    return "";
  };

  return (
    <Box
      p="20px"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: "40px",
        backdropFilter: "blur(10px)",
        padding:
          deviceType === "Mobile" || deviceType === "Tablet" ? "12px" : "15px",
        display: "flex",
        alignItems: "center",
        // width: "100%",
        flexDirection:
          deviceType === "Mobile" || deviceType === "Tablet" ? "column" : "row",
        height:
          deviceType === "Mobile" || deviceType === "Tablet" ? "auto" : "50px",
      }}
    >
      <Group
        wrap="nowrap"
        justify="space-between"
        align="center"
        style={{
          width: "100%",
          gap:
            deviceType === "Mobile" || deviceType === "Tablet"
              ? "12px"
              : "20px",
          overflow: "hidden",
        }}
      >
        <Group
          wrap="nowrap"
          gap="md"
          align="center"
          style={{
            flex: 1,
            justifyContent:
              deviceType === "Mobile" || deviceType === "Tablet"
                ? "space-between"
                : "center",
            overflow: "hidden",
          }}
        >
          <Group wrap="nowrap" gap="xs" style={{ flexShrink: 0 }}>
            <img
              src={LocationIcon}
              alt=""
              style={{
                width:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                height:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                filter: `invert(${iconColor})`,
                flexShrink: 0,
              }}
            />
            {deviceType === "Desktop" && (
              <Text
                {...textProps}
                style={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                {country}
              </Text>
            )}
          </Group>

          {deviceType === "Desktop" && <Divider />}

          <Group wrap="nowrap" gap="xs" style={{ flexShrink: 0 }}>
            <img
              src={VisitorIcon}
              alt=""
              style={{
                width:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                height:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                filter: `invert(${iconColor})`,
                flexShrink: 0,
              }}
            />
            {deviceType === "Desktop" && (
              <Text
                {...textProps}
                style={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                {purpose}
              </Text>
            )}
          </Group>

          {deviceType === "Desktop" && <Divider />}

          <Group wrap="nowrap" gap="xs" style={{ flexShrink: 0 }}>
            <img
              src={ClockIcon}
              alt=""
              style={{
                width:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                height:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                filter: `invert(${iconColor})`,
                flexShrink: 0,
              }}
            />
            {deviceType === "Desktop" && (
              <Text
                {...textProps}
                style={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                }}
              >
                {differenceInDays(
                  parseDate(toDate!) || new Date(),
                  parseDate(fromDate!) || new Date()
                )}{" "}
                days
              </Text>
            )}
          </Group>

          {deviceType === "Desktop" && <Divider />}

          <Group
            wrap="nowrap"
            gap="xs"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={CalendarIcon}
              alt=""
              style={{
                width:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                height:
                  deviceType === "Mobile" || deviceType === "Tablet"
                    ? "20px"
                    : "14px",
                // marginRight: "px",
                // filter: `invert(${iconColor})`,
              }}
            />
            <DatePickerInput
              variant="unstyled"
              style={{
                color: "white",
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "250px",
                fontSize: "15px",
                paddingTop: "2px",
              }}
              minDate={new Date()}
              type="range"
              value={value}
              onChange={setValue}
              valueFormatter={formatter}
              disabled={disabledDateChange}
              styles={{
                input: {
                  backgroundColor: "transparent",
                },
              }}
            />
          </Group>
        </Group>

        {value[0] &&
          value[1] &&
          (value[0] !== fromDate || value[1] !== toDate) && (
            <Button
              variant="default"
              onClick={() => onChangeClick!(value)}
              styles={{
                root: {
                  background: primaryColor,
                  backdropFilter: "blur(10px)",
                  border: "1px solid white",
                  color: "white",
                  borderRadius: "20px",
                  height:
                    deviceType === "Mobile" || deviceType === "Tablet"
                      ? "32px"
                      : "36px",
                  padding: "0 16px",
                  minWidth:
                    deviceType === "Mobile" || deviceType === "Tablet"
                      ? "80px"
                      : "100px",
                  flexShrink: 0,
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.1)",
                  },
                },
              }}
            >
              Update
            </Button>
          )}
      </Group>
    </Box>
  );
}
