import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  Image,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import classes from "./authenticationComponent.module.css";
import logo from "../../assets/logo.png";

interface IAuthenticationComponentProps {
  userName: string;
  password: string;
  loading: boolean;
  showPasswordChange: boolean;
  newPassword: string;
  confirmPassword: string;
  onUserNameChange: (userName: string) => void;
  onPasswordChange: (password: string) => void;
  onNewPasswordChange: (newPassword: string) => void;
  onConfirmPasswordChange: (confirmPassword: string) => void;
  onSignIn: () => void;
}

interface PasswordRequirement {
  text: string;
  isValid: boolean;
}

const validatePassword = (password: string): PasswordRequirement[] => {
  const requirements: PasswordRequirement[] = [
    {
      text: "Password must be at least 12 characters long.",
      isValid: password.length >= 12,
    },
    {
      text: "Password must contain at least one uppercase letter.",
      isValid: /[A-Z]/.test(password),
    },
    {
      text: "Password must contain at least one lowercase letter.",
      isValid: /[a-z]/.test(password),
    },
    {
      text: "Password must contain at least one number.",
      isValid: /[0-9]/.test(password),
    },
    {
      text: "Password must contain at least one special character.",
      isValid: /[!@#$%^&*()-_=+\[\]{}|;:,.<>?]/.test(password),
    },
  ];
  return requirements;
};

export function AuthenticationComponent({
  onPasswordChange,
  onSignIn,
  onUserNameChange,
  onNewPasswordChange,
  onConfirmPasswordChange,
  password,
  userName,
  loading,
  showPasswordChange,
  newPassword,
  confirmPassword,
}: IAuthenticationComponentProps) {
  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([]);

  const handleNewPasswordChange = (value: string) => {
    onNewPasswordChange(value);
    const requirements = validatePassword(value);
    setPasswordRequirements(requirements);
  };

  return (
    <div
      style={{
        marginTop: "5%",
      }}
    >
      <Container size={430} my={40}>
        <Image
          src={logo}
          alt="Intellivisa"
          style={{
            width: "65px",
            height: "65px",
            margin: "auto",
            display: "block",
            marginBottom: "20px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title ta="center" className={classes.title}>
            Intellivisa
          </Title>
        </div>

        <Text c="dimmed" size="md" ta="center" mt={5}>
          Sign in to your account to continue
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          {!showPasswordChange && (
            <>
              <TextInput
                label="Username"
                placeholder="Your username"
                required
                value={userName}
                onChange={(event) => {
                  onUserNameChange(event.currentTarget.value);
                }}
              />

              <PasswordInput
                label="Password"
                placeholder="Your password"
                required
                mt="md"
                value={password}
                onChange={(event) => {
                  onPasswordChange(event.currentTarget.value);
                }}
              />
            </>
          )}

          {showPasswordChange && (
            <>
              <PasswordInput
                label="New Password"
                placeholder="Enter new password"
                required
                mt="md"
                value={newPassword}
                onChange={(event) => {
                  handleNewPasswordChange(event.currentTarget.value);
                }}
              />
              <PasswordInput
                label="Confirm Password"
                placeholder="Confirm new password"
                required
                mt="md"
                value={confirmPassword}
                onChange={(event) => {
                  onConfirmPasswordChange(event.currentTarget.value);
                }}
              />
              <div>
                {passwordRequirements.map((requirement, index) => (
                  <Text
                    key={index}
                    color={requirement.isValid ? "green" : "red"}
                    size="xs" // Decreased font size
                    mt="sm"
                    style={{ lineHeight: 0.7 }} // Decreased line height
                  >
                    {requirement.isValid ? (
                      <IconCheck size={16} />
                    ) : (
                      <IconX size={16} />
                    )}{" "}
                    {requirement.text}
                  </Text>
                ))}
              </div>
            </>
          )}
          <Button
            loading={loading}
            fullWidth
            mt="xl"
            onClick={() => {
              onSignIn();
            }}
            disabled={
              showPasswordChange &&
              passwordRequirements.some((req) => !req.isValid)
            }
          >
            {showPasswordChange ? "Update Password" : "Sign In"}
          </Button>
        </Paper>
      </Container>

      <Text c="dimmed" size="sm" ta="center" mt={50}>
        © 2024 intellivisa. All rights reserved
      </Text>
    </div>
  );
}
