import { LoadingOverlay } from "@mantine/core";
import React, { useState } from "react";
import {
  BRAND_COLOR,
  BRAND_LOGO_URL,
  FAVICON_BASE64,
  IV_TOKEN,
  IV_USER,
  REALM_CLIENT_ID,
} from "../constants/storage.constants";
import axios from "axios";
import Keycloak from "keycloak-js";
import authService from "../services/auth.service";
import profileService from "../services/profile.service";

interface IKeyCloakContextProps {
  logout: () => void;
  login: () => void;
  register: () => void;
  isAuthenticated?: () => boolean;
  keycloak?: Keycloak.KeycloakInstance;
  getRealmRoles?: () => string[];
}

export interface IKCUserData {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role: string[] | "applicant";
}

interface IKeyCloakState {
  isLoaded: boolean;
}

type KeyCloakStateActions = { type: "loaded"; isLoaded: boolean };

const KeyCloakContextStateReducer = (
  state: IKeyCloakState,
  action: KeyCloakStateActions
) => {
  switch (action.type) {
    case "loaded":
      return { ...state, isLoaded: action.isLoaded };
    default:
      return state;
  }
};

function formatDate(timestamp: number): string {
  return new Date(timestamp * 1000).toISOString();
}

export const KeyCloakContext = React.createContext<
  Partial<IKeyCloakContextProps>
>({});

function logWithDate(message: string, ...optionalParams: any[]) {
  const now = new Date();
  const timestamp = now.toISOString();
  console.log(`[${timestamp}] ${message}`, ...optionalParams);
}

export const KeyCloakProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = React.useReducer(KeyCloakContextStateReducer, {
    isLoaded: false,
  } as IKeyCloakState);
  const isAdminClient = React.useRef(false);

  const [keycloakInstance, setKeycloakInstance] = React.useState<
    Keycloak.KeycloakInstance | undefined
  >(undefined);
  const kcInitialized = React.useRef(false);
  const gotKcConfig = React.useRef(false);
  const [kcConfig, setKcConfig] = useState({
    url: "",
    realm: "",
    clientId: "",
  });

  /**
   * Verify the token and get the Keycloak profile
   * @param token
   * @returns
   */
  const verifyTokenAndGetKCProfile = async (token: string): Promise<any> => {
    try {
      const response = await authService.validateUserToken(token);
      return response;
    } catch (error) {
      console.error("Token verification failed:", error);
      return { tokenValid: false };
    }
  };

  React.useEffect(() => {
    if (gotKcConfig.current) return;

    isAdminClient.current =
      (window.location.pathname.includes(
        import.meta.env.VITE_KEYCLOAK_CLIENT_ID
      ) ||
        window.location.pathname === "/") &&
      import.meta.env.VITE_FRONTEND_URL === window.location.origin;

    const setConfigFromResponse = (
      responseData:
        | {
            response: {
              realmAuthorizationUrl: string;
              realmId: string;
              realmClientId: string;
              brandAssets: {
                brandLogoImage: string;
                brandColor: string;
                brandFavicon: string;
              };
              realmDisplayName?: string;
            };
          }
        | undefined
    ) => {
      if (!responseData?.response) {
        throw new Error("Invalid response format");
      }

      const {
        realmAuthorizationUrl,
        realmId,
        realmClientId,
        brandAssets,
        realmDisplayName,
      } = responseData.response;

      setKcConfig({
        url: realmAuthorizationUrl,
        realm: realmId,
        clientId: realmClientId,
      });

      if (realmDisplayName) {
        document.title = realmDisplayName;
      }

      if (brandAssets?.brandFavicon) {
        const faviconLink = document.querySelector("link[rel*='icon']") as any;
        faviconLink.href = `data:image/png;base64,${brandAssets.brandFavicon}`;
      }

      localStorage.setItem(REALM_CLIENT_ID, realmClientId);
      localStorage.setItem(BRAND_LOGO_URL, brandAssets?.brandLogoImage || "");
      localStorage.setItem(BRAND_COLOR, brandAssets?.brandColor || "#fd640f");
      localStorage.setItem(FAVICON_BASE64, brandAssets?.brandFavicon || "");
      localStorage.setItem("realmDisplayName", realmDisplayName || "");
    };

    const fetchKeyCloakConfiguration = async () => {
      try {
        const url = window.location.href;
        let response;

        if (url.includes("intellivisa") || url.includes("localhost")) {
          const path =
            window.location.pathname === "/"
              ? `/${import.meta.env.VITE_KEYCLOAK_CLIENT_ID}`
              : window.location.pathname;

          response = await axios.get(import.meta.env.VITE_KC_PUB_REALM + path);
        } else {
          response = await axios.post(import.meta.env.VITE_KC_MASKED_URL, {
            maskedUrl: window.location.origin,
          });
        }

        if (response?.data) {
          setConfigFromResponse(response.data);
        } else {
          throw new Error("Empty response received");
        }
      } catch (error: any) {
        console.error(
          "Failed to fetch Keycloak configuration:",
          error.message || error
        );
      } finally {
        gotKcConfig.current = true;
      }
    };

    fetchKeyCloakConfiguration();
  }, []);

  React.useEffect(() => {
    if (!kcConfig.url || !kcConfig.realm || !kcConfig.clientId) {
      return;
    }

    if (kcInitialized.current) return;

    const initializeAuth = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const bearerToken = urlParams.get("bearerToken");
        const visaType = urlParams.get("visaType");
        const visaDuration = urlParams.get("visaDuration");
        const fromDate = urlParams.get("fromDate");
        const toDate = urlParams.get("toDate");
        const sourceCountry = urlParams.get("sourceCountry");
        const destinationCountry = urlParams.get("destinationCountry");
        const applicationCreationKey = urlParams.get("applicationCreationKey");
        const premiumVerificationKey = urlParams.get("premiumVerificationKey");
        const applicantUid = urlParams.get("applicantUid");
        const agencyUid = urlParams.get("agencyUid");
        const visaCategory = urlParams.get("visaCategory");

        const ivToken = localStorage.getItem(IV_TOKEN);

        // Check for bearer token in URL params
        if (bearerToken && isAdminClient.current === false) {
          const tokenToUse = bearerToken;
          await handleBearerTokenAuth(tokenToUse!);

          // Create new URL parameters without the bearer token
          const cleanUrlParams = new URLSearchParams();

          if (visaType) cleanUrlParams.set("visaType", visaType);
          if (visaDuration) cleanUrlParams.set("visaDuration", visaDuration);
          if (fromDate) cleanUrlParams.set("fromDate", fromDate);
          if (toDate) cleanUrlParams.set("toDate", toDate);
          if (sourceCountry) cleanUrlParams.set("sourceCountry", sourceCountry);
          if (visaCategory) cleanUrlParams.set("visaCategory", visaCategory);
          if (destinationCountry)
            cleanUrlParams.set("destinationCountry", destinationCountry);
          if (applicationCreationKey)
            cleanUrlParams.set(
              "applicationCreationKey",
              applicationCreationKey
            );
          if (premiumVerificationKey)
            cleanUrlParams.set(
              "premiumVerificationKey",
              premiumVerificationKey
            );
          if (applicantUid) cleanUrlParams.set("applicantUid", applicantUid);
          if (agencyUid) cleanUrlParams.set("agencyUid", agencyUid);

          // Update URL without the bearer token but keeping other parameters
          const queryString = cleanUrlParams.toString();
          const baseUrl = window.location.origin;
          const clientPath = window.location.pathname;

          let newPath = "";
          if (
            visaCategory &&
            fromDate &&
            toDate &&
            sourceCountry &&
            destinationCountry &&
            applicationCreationKey &&
            applicantUid
          ) {
            // Construct the proper URL with the get-visa route
            newPath = `${baseUrl}${clientPath}#/get-visa?${queryString}`;
            console.log(`Redirecting to visa application page: ${newPath}`);
            window.history.replaceState({}, document.title, newPath);
            window.location.reload();
            return;
          }

          newPath = queryString
            ? `${window.location.pathname}?${queryString}`
            : window.location.pathname;
          window.history.replaceState({}, document.title, newPath);
          window.location.reload();
          return;
        }

        if (isAdminClient.current) {
          await initializeKeycloak();
        }

        if (!ivToken || (!isAdminClient.current && !bearerToken)) {
          dispatch({ type: "loaded", isLoaded: true });
          return;
        }
      } catch (error) {
        console.error("Authentication failed:", error);
        dispatch({ type: "loaded", isLoaded: true });
      }
    };

    initializeAuth();

    kcInitialized.current = true;
  }, [kcConfig.clientId]);

  const handleBearerTokenAuth = async (token: string) => {
    const tokenVerification = await verifyTokenAndGetKCProfile(token);
    if (tokenVerification.tokenValid) {
      logWithDate("User authenticated via access_token");
      localStorage.setItem(IV_TOKEN, token);

      const profileRes = await profileService.profile(
        tokenVerification?.userData?.id,
        tokenVerification?.userData?.role?.filter(
          (role: any) =>
            role === "admin" ||
            role === "agency" ||
            role === "agent" ||
            role === "applicant" ||
            role === "reviewer" ||
            role === "distributor"
        )?.[0]
      );

      // Update user response with profile
      const updatedUserResponse = {
        ...(profileRes?.response || {}),
        ...tokenVerification?.userData,
      };

      localStorage.setItem(IV_USER, JSON.stringify(updatedUserResponse));

      dispatch({ type: "loaded", isLoaded: false });
      kcInitialized.current = true; // Prevent Keycloak initialization
    } else {
      throw new Error("Invalid bearer token");
    }
  };

  const initializeKeycloak = async () => {
    const kc = new Keycloak({
      url: kcConfig.url,
      realm: kcConfig.realm,
      clientId: kcConfig.clientId,
    });

    const authenticated = await kc.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });

    if (authenticated) {
      setKeycloakInstance(kc);
      localStorage.setItem(IV_TOKEN, kc.token || "");
      logWithDate("User authenticated via Keycloak");
      setupKeycloakHandlers(kc);
    } else {
      logWithDate("User not authenticated");
    }

    dispatch({ type: "loaded", isLoaded: true });
  };

  const setupKeycloakHandlers = (kc: Keycloak.KeycloakInstance) => {
    kc.onAuthRefreshSuccess = () => {
      logWithDate("Auth refresh success");
      localStorage.setItem(IV_TOKEN, kc.token || "");
    };

    kc.onAuthRefreshError = () => {
      logWithDate(
        "Auth refresh error: Invalid session or client configuration."
      );
      kc.logout({ redirectUri: window.location.origin });
    };

    const checkTokenExpiration = () => {
      if (kc.isTokenExpired(60)) {
        logWithDate("Token is expired or will expire in 60 seconds");
        kc.updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              const exp = kc.tokenParsed?.exp;
              if (exp) {
                const expiryDate = formatDate(exp);
                logWithDate("Token refreshed", `Expires at: ${expiryDate}`);
              } else {
                logWithDate(
                  "Token refreshed but expiration date not available"
                );
              }
              localStorage.setItem(IV_TOKEN, kc.token || "");
            } else {
              logWithDate("Token is still valid");
            }
          })
          .catch((error) => {
            logWithDate("Failed to refresh token", error);
          });
      }
    };

    const refreshInterval = 30000; // 30 seconds
    const intervalId = setInterval(checkTokenExpiration, refreshInterval);
    return () => clearInterval(intervalId);
  };

  if (
    !state.isLoaded ||
    (isAdminClient.current && keycloakInstance === undefined)
  ) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <KeyCloakContext.Provider
      value={{
        logout: () => {
          localStorage.removeItem(IV_TOKEN);
          if (keycloakInstance) {
            keycloakInstance.logout({
              redirectUri: `${window.location.origin}/${kcConfig?.clientId}`,
            });
          } else {
            window.location.href = `${window.location.origin}/${kcConfig?.clientId}`;
          }
        },
        isAuthenticated: () => !!localStorage.getItem(IV_TOKEN),
        keycloak: keycloakInstance,
        getRealmRoles: () =>
          keycloakInstance?.tokenParsed?.realm_access?.roles || [],
      }}
    >
      {children}
    </KeyCloakContext.Provider>
  );
};

export function useKeyCloak() {
  const context = React.useContext(KeyCloakContext);
  if (context === undefined) {
    throw new Error("useKeyCloak must be used inside KeyCloakProvider");
  }
  return context;
}
