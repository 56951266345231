import { createTheme, LoadingOverlay, MantineProvider } from "@mantine/core";
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { getB2BRoutes, getD2CRoutes } from "./RouteImports";
import { NavbarApp } from "../components/navbarApp/NavbarApp";
import { IRouteObject } from "../types/route.types";
import { useUser } from "../contexts/UserContext";
import { FourZeroFour } from "../components/fourZeroFour/FourZeroFour";
import { isInRole } from "../utils/common.util";
import { AppHeader } from "../components/d2c/header/AppHeader";
import { APPLICANT_TYPES } from "../constants/common.constants";
import { b2bTheme, generateTheme } from "../theme";
import { BRAND_COLOR, BRAND_LOGO_URL } from "../constants/storage.constants";
import { ApplicationProvider } from "../contexts/ApplicationContext";
import BackgroundGradient from "../components/d2c/common/BackgroundGradient";

interface ProtectedRouteProps extends IRouteObject {
  userRoles: string[];
}

/**
 * ProtectedRoute component to handle role-based route protection.
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  roles,
  userRoles,
}) => {
  return isInRole(roles || [], userRoles) ? <Component /> : <FourZeroFour />;
};

const B2BRoutes = ({ userRoles }: { userRoles: string[] }) => {
  return (
    <Routes>
      {getB2BRoutes()?.map((routes, i) => (
        <Route
          key={`RouteWeb-${i}`}
          path={routes.path}
          element={
            <ProtectedRoute
              component={routes.component}
              roles={routes.roles || []}
              userRoles={userRoles || []}
              path={routes.path}
              exact={routes.exact}
            />
          }
        />
      ))}
    </Routes>
  );
};

const D2CRoutes = ({ userRoles }: { userRoles: string[] }) => {
  return (
    <Routes>
      {getD2CRoutes()?.map((routes, i) => (
        <Route
          key={`RouteWeb-${i}`}
          path={routes.path}
          element={
            <ProtectedRoute
              component={routes.component}
              roles={routes.roles || []}
              userRoles={userRoles || []}
              path={routes.path}
              exact={routes.exact}
            />
          }
        />
      ))}
    </Routes>
  );
};

const SuspenseFallback = () => <LoadingOverlay visible={true} />;

const RouteChildWrapper = () => {
  const { getCurrentUserRoles, currentUserProfile, filterRoles } = useUser();
  const userRoles = getCurrentUserRoles ? getCurrentUserRoles() : [];
  const isD2c =
    userRoles &&
    filterRoles?.(userRoles)?.[0] === "applicant" &&
    currentUserProfile?.applicantType === APPLICANT_TYPES.D2C
      ? true
      : false;

  const brandColor = localStorage.getItem(BRAND_COLOR) || "";
  const brandLogo = localStorage.getItem(BRAND_LOGO_URL) || "";
  const theme = {
    ...(isD2c ? createTheme(generateTheme(brandColor)) : b2bTheme),
    fontFamily: "Kumbh Sans, sans-serif",
  };

  const Content = isD2c ? (
    <BackgroundGradient>
      <AppHeader logoUrl={brandLogo} />
      <React.Suspense fallback={<SuspenseFallback />}>
        <D2CRoutes userRoles={userRoles} />
      </React.Suspense>
    </BackgroundGradient>
  ) : (
    <NavbarApp>
      <React.Suspense fallback={<SuspenseFallback />}>
        <B2BRoutes userRoles={userRoles} />
      </React.Suspense>
    </NavbarApp>
  );

  return (
    <MantineProvider
      theme={theme}
      withCssVariables
      withGlobalClasses
      defaultColorScheme="light"
    >
      <ApplicationProvider>{Content}</ApplicationProvider>
    </MantineProvider>
  );
};

export const RouteConfigs = () => {
  return (
    <HashRouter>
      <RouteChildWrapper />
    </HashRouter>
  );
};
